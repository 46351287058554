import { Layer, Box, Heading } from "grommet";
import { Close, Checkmark } from "grommet-icons";

const Modals = ({ toast, setToast, success }) => {
  return (
    <>
      {toast && (
        <Layer
          animate
          onClickOutside={() => setToast(undefined)}
          responsive={false}
          modal
        >
          <Box
            align="start"
            justify="center"
            flex={false}
            pad={{ vertical: "medium" }}
            background={{ color: "active-text" }}
            direction="column"
            round="medium"
            gap="medium"
            onClick={() => setToast(undefined)}
            width="20vmax"
          >
            <Box
              align="center"
              justify="center"
              alignSelf="end"
              fill="horizontal"
            >
              <Heading
                responsive
                textAlign="center"
                size="1.5rem"
                align="center"
                margin="none"
              >
                {toast}
              </Heading>
            </Box>

            <Box
              align="center"
              justify="center"
              alignSelf="center"
              animation={{ type: "slideUp", size: "xlarge", duration: 1000 }}
              background={{ color: `${success ? "accent-1" : "status-error"}` }}
              round="full"
              pad="medium"
            >
              {success ? <Checkmark size="large" /> : <Close size="large" />}
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default Modals;
