import { Layer, Box, Button, Heading, Paragraph, TextInput } from "grommet";
import styled from "styled-components";
import { Close, Twitter } from "grommet-icons";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { CLOUD } from "../constant/abi";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

const hashed = (words) => ethers.utils.solidityKeccak256(["string"], [words]);

const beProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_BE
);

const contractBackend = new ethers.Contract(
  process.env.REACT_APP_BACK,
  CLOUD,
  beProvider
);

const endPoint = process.env.REACT_APP_BACKEND_URL;

const twitter = "https://t.co/unpQ2QZOrA";

const validateWhitelist = async (_username, _url, _urlTwitter) => {
  const _validUrlTwitter = await contractBackend.isValidUrl(_urlTwitter);
  const _waiting = await contractBackend.stillWaiting(_username);
  const _validUrl = await contractBackend.urlContent(_url);

  let validate = {
    waiting: _waiting,
    urlTwitter: _validUrlTwitter,
    validUrl: _validUrl,
  };
  return validate;
};

const priceUpdate = (_supply) => {
  let remain;
  const formatedMinted = (_num, __supply) => {
    if (_num < 1000 || _num > 9999) {
      return `${_num} / ${__supply}`;
    } else {
      const str = _num.toString();
      const arrStr = str.split("");
      arrStr.splice(1, 0, ".");
      return `${arrStr.join("")} Token`;
    }
  };
  const penutup = "Token remain until cost to mint update :";
  if (_supply <= 3000) {
    remain = 3000 - _supply;
  }
  if (_supply > 3000 && _supply <= 5000) {
    remain = 5000 - _supply;
  }

  if (_supply > 5000 && _supply <= 8000) {
    remain = 8000 - _supply;
  }
  if (_supply > 8000) {
    remain = 10000 - _supply;
    return (
      <Paragraph>
        {formatedMinted(remain)} remain until reached max supply
      </Paragraph>
    );
  }
  return (
    <>
      <Paragraph>{penutup}</Paragraph>
      <Paragraph size="xlarge">{formatedMinted(remain)}</Paragraph>
    </>
  );
};

const DAOAddress = process.env.REACT_APP_DAO;
const StyledBox = styled(Box)`
  ::-webkit-scrollbar-track {
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(136, 136, 136, 0.4);
  }
`;

const RoadMap = ({
  modalRoad,
  openRoad,
  lvldaos,
  lvlmints,
  claimWhitelist,
  relayer,
  contractNFT,
  minted,
}) => {
  const [isFollow, setIsFollow] = useState(false);
  const [url, setUrl] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [activateButton, setActivateButton] = useState(false);
  const [validAddress, setValidAddress] = useState(false);
  const [openWL, setOpenWl] = useState(false);
  const [leaf, setLeaf] = useState();
  const [notEOA, setNotEOA] = useState(false);
  const [notWhitelist, setNotWhitelist] = useState(false);
  const [totalWhitelist, setTotalWhitelist] = useState(0);
  const [listWL, setListWL] = useState([]);
  const [claimed, setClaimed] = useState(false);
  const [isError, setError] = useState(false);
  const [isErrorFetch, setIsErrorFetch] = useState(false);
  const [errMessage, setErrMesage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState({});
  const [errorFetch, setErrorFetch] = useState({ success: true, error: "" });
  const [savedURL, setSavedURL] = useState([]);
  const [newUrl, setNewURL] = useState(null);

  useEffect(() => {
    try {
      setNewURL(null);
      setSuccess(false);
      setError(false);
      setIsErrorFetch(false);
      if (openWL) return;
      if (url.length < 25) return;
      if (url.includes("twitter.com/")) {
        if (!url.includes("/status/")) {
          setError(true);
          setErrMesage("no status found");
          return;
        }
        let sliceurl = url.split("twitter.com/");
        sliceurl = sliceurl.pop();
        const urlTwitter = sliceurl.split("/");
        if (urlTwitter.length !== 3) return;
        const _username = urlTwitter.shift();
        let id = urlTwitter.pop();

        const questionMark = id.includes("?");
        if (questionMark) {
          id = id.split("?");
          id = id.shift();
        }
        isBot(_username, id);
      } else {
        setError(true);
        setErrMesage("Invalid URL Twitter");
      }
    } catch (err) {
      console.error(err);
    }
    return () => {};
  }, [url]);

  const generate = (numb) => {
    const byteslike = ethers.utils.toUtf8Bytes(
      `${process.env.REACT_APP_MIDDLE}${numb}`
    );
    const circleing = ethers.utils.keccak256(byteslike);
    const hash = ethers.utils.solidityKeccak256(
      ["string", "bytes32"],
      [process.env.REACT_APP_OPENING, circleing]
    );

    const relay = relayer.signDigest(hash);

    return relay;
  };

  useEffect(() => {
    setNotWhitelist(false);
    setNotEOA(false);
    setValidAddress(false);
    setClaimed(false);
    if (valueAddress.length < 42) return;
    const validateAddress = ethers.utils.isAddress(valueAddress);
    if (!validateAddress) {
      setNotEOA(true);
      return;
    } else {
      setNotEOA(false);
      checkWL(valueAddress);
    }
  }, [valueAddress]);

  useEffect(() => {
    if (!modalRoad) return;
    setTotal();
    checkOpen();

    return () => {
      setValueAddress("");
      setUrl("");
    };
  }, [modalRoad]);

  async function setTotal() {
    const totalWL = await contractBackend.totalWhiteAddress();
    setTotalWhitelist(totalWL.toNumber());
  }

  const checkOpen = async () => {
    const listAddress = await contractBackend.getWhitelist();
    setListWL(listAddress);
    const _isOpen = await contractBackend.whitelistOpen();
    if (!_isOpen) return;
    setOpenWl(_isOpen);
    const leafNode = listAddress.map((addr) => keccak256(addr));
    setLeaf(leafNode);
  };

  const checkWL = async (_address) => {
    const isWhitelist = listWL.includes(_address);
    if (!isWhitelist) {
      setNotWhitelist(true);
      return;
    } else {
      setNotWhitelist(false);
      const isClaimed = await contractNFT.whitelistClaimed(_address);
      if (isClaimed) {
        setClaimed(true);
        return;
      } else {
        setClaimed(false);
        setValidAddress(true);
      }
    }
  };

  const getProof = (_address) => {
    const merkletree = new MerkleTree(leaf, keccak256, { sortPairs: true });
    const proof = merkletree.getHexProof(keccak256(_address));
    return proof;
  };

  const isBot = async (_user, id) => {
    try {
      const data = await ethers.utils.fetchJson(`${endPoint}/user/${_user}`);
      if (data.success) {
        setActivateButton(true);
        setNewURL(`https://twitter.com/${_user}/status/${id}`);
      }
    } catch (err) {
      setIsErrorFetch(true);
      setErrorFetch(JSON.parse(err.body));
    }
  };

  const validating = async () => {
    setActivateButton(false);
    // const isSaved = savedURL.includes(newUrl);
    // if (isSaved) {
    //   setError(true);
    //   setErrMesage("Can't submit same URL more than one");
    //   return;
    // }
    // setSavedURL((oldURL) => [...oldURL, url]);
    const urlBase = newUrl.split("/");
    const urlLink = urlBase.slice(0, 3).join();
    const _username = urlBase[3];
    const contentId = urlBase.pop();
    setUrl("");
    if (!_username && !contentId) {
      return;
    }

    const { waiting, urlTwitter, validUrl } = await validateWhitelist(
      hashed(_username),
      hashed(newUrl),
      hashed(urlLink)
    );

    const block = await beProvider.getBlockNumber();
    const blockBefore = await beProvider.getBlock(block);
    const deadline = blockBefore.timestamp + 360;
    const relayHash = generate(deadline);
    try {
      const passed = !validUrl && !waiting && urlTwitter;
      if (passed) {
        const data = await ethers.utils.fetchJson(
          `${endPoint}/content/${contentId}&${_username}`
        );

        setSuccess(true);
        setSuccessMsg(data);
        await setTotal();
        await checkOpen();
      } else {
        setError(true);
        if (waiting) {
          setErrMesage(
            "To avoid spam, same twitter account must wait 4 hours and submit another address with new URL"
          );
          return;
        }
        if (validUrl) {
          setErrMesage("Please submit another link");
          return;
        }
        if (!urlTwitter) {
          setErrMesage("Not Valid Url Twitter");
          return;
        }
        if (waiting && !validUrl) {
          setErrMesage(
            "To avoid spam, same twitter account must wait 4 hours and submit another address with new URL"
          );
          await ethers.utils.fetchJson(
            `${endPoint}/reject/${relayHash.compact}&${contentId}&${_username}&${deadline}`
          );
          return;
        }
      }
    } catch (err) {
      setIsErrorFetch(true);
      setErrorFetch(JSON.parse(err.body));
      console.error(JSON.parse(err.body));
      if (err.code === 501) {
        await ethers.utils.fetchJson(
          `${endPoint}/reject/${relayHash.compact}&${contentId}&${_username}&${deadline}`
        );
      }
    } finally {
      setValueAddress("");
      setNotWhitelist(false);
      setNotEOA(false);
      setValidAddress(false);
      setClaimed(false);
    }
  };

  const transWL = async (_address) => {
    try {
      const proof = getProof(_address);
      await claimWhitelist(_address, proof);
    } catch (err) {
      console.error(err);
    } finally {
      setValidAddress(false);
      setValueAddress("");
    }
  };

  return (
    <>
      {modalRoad && (
        <Layer
          animate
          modal={false}
          position="right"
          responsive
          full="vertical"
        >
          <StyledBox
            align="center"
            justify="start"
            direction="column"
            overflow="auto"
            gap="none"
            responsive
            margin={{ bottom: "medium" }}
          >
            <StyledBox
              align="center"
              justify="between"
              direction="row"
              border={{ color: "active-text", side: "bottom" }}
              flex={false}
              width="100%"
              pad="small"
            >
              <StyledBox
                align="center"
                justify="center"
                direction="column"
                alignSelf="start"
              >
                <Button
                  icon={<Close />}
                  color="active-text"
                  primary
                  onClick={() => {
                    openRoad(undefined);
                  }}
                />
              </StyledBox>
              <StyledBox align="center" justify="center" width="100%">
                <Heading textAlign="center" level="2">
                  ROADMAP
                </Heading>
              </StyledBox>
            </StyledBox>
            <StyledBox
              fill="horizontal"
              align="center"
              flex={false}
              pad="small"
              elevation="large"
              margin="medium"
              round="small"
            >
              {priceUpdate(minted)}
            </StyledBox>
            <StyledBox
              gap="small"
              pad="large"
              margin="small"
              elevation="large"
              fill="horizontal"
              justify="center"
              flex={false}
              round="small"
              align="center"
            >
              <Paragraph textAlign="center">
                - 80% Royalty are for Donation.
              </Paragraph>
              <Paragraph textAlign="center">
                - 15% Royalty are for Minter, proporsional of the amount holder
                mint.
              </Paragraph>
              <Paragraph textAlign="center">
                - Right now, the DAO proposal are only determine the next
                donation recipient.
              </Paragraph>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              gap="none"
              round="small"
              pad="small"
              elevation="small"
              direction="column"
              margin={{ top: "medium" }}
              alignSelf="center"
              flex={false}
              background={{ color: "active-text" }}
            >
              <Heading margin="none" level="3" textAlign="center">
                Contract Deployed
              </Heading>
              <Paragraph
                margin="none"
                color="black"
                textAlign="center"
                size="medium"
              >
                Passed
              </Paragraph>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              flex={false}
              background={{ color: "active-text" }}
              animation={{ type: "fadeIn", size: "xlarge" }}
              pad="xsmall"
            />
            <StyledBox
              align="end"
              justify="center"
              direction="row"
              flex={false}
            >
              <StyledBox align="start" justify="start">
                <StyledBox
                  align="center"
                  justify="center"
                  background={{ color: "active-text" }}
                  width="xxsmall"
                  pad="xsmall"
                  animation={{ type: "slideLeft", size: "xlarge" }}
                />
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  height="xxsmall"
                  pad="xsmall"
                />
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                pad="medium"
                round="xsmall"
                elevation="xsmall"
                flex={false}
                alignSelf="center"
                background={{ color: "active-text" }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Public Mint
                </Heading>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox align="end" justify="start">
                <StyledBox
                  align="center"
                  justify="center"
                  background={{ color: "active-text" }}
                  width="xxsmall"
                  pad="xsmall"
                  animation={{ type: "slideRight", size: "xlarge" }}
                />
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  height="xxsmall"
                  pad="xsmall"
                />
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="stretch"
              justify="center"
              direction="row"
              flex={false}
              wrap={false}
              gap="xlarge"
              width="100%"
            >
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                direction="column"
                pad="xsmall"
                round="xsmall"
                elevation="large"
                basis="small"
                flex={false}
                background={{ color: `${lvlmints > 1 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Minted 3000 NFT
                </Heading>
                {lvlmints < 2 && (
                  <Paragraph margin="none" textAlign="center">
                    Cost to Mint Updated
                  </Paragraph>
                )}
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                pad="small"
                round="xsmall"
                elevation="xlarge"
                wrap
                basis="small"
                flex={false}
                background={{ color: `${lvldaos > 0 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  DAO START
                </Heading>
                <Paragraph margin="none" textAlign="center">
                  3 Month after contract deployed
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              direction="row"
              width="100%"
              flex={false}
              gap="xlarge"
            >
              <StyledBox align="center" justify="center" width="small">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  pad="xsmall"
                  height="xxsmall"
                />
              </StyledBox>
              <StyledBox align="center" justify="center" width="small">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  pad="xsmall"
                  height="xxsmall"
                />
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              direction="row"
              fill="horizontal"
              pad="xsmall"
              responsive
              flex={false}
              gap="xlarge"
            >
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                direction="column"
                pad="xsmall"
                round="xsmall"
                elevation="xlarge"
                basis="small"
                background={{ color: `${lvlmints > 2 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Minted 5000 NFT
                </Heading>
                {lvlmints < 3 && (
                  <Paragraph margin="none" textAlign="center">
                    Cost to Mint Updated
                  </Paragraph>
                )}
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                pad="xsmall"
                round="xsmall"
                elevation="xlarge"
                basis="small"
                background={{ color: `${lvldaos > 1 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  1st Submit Propsal
                </Heading>
                <Paragraph margin="none" textAlign="center">
                  from NFT Holder
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              direction="row"
              width="100%"
              flex={false}
              gap="xlarge"
            >
              <StyledBox align="center" justify="center" width="small">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  pad="xsmall"
                  height="xxsmall"
                />
              </StyledBox>
              <StyledBox align="center" justify="center" width="small">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  pad="xsmall"
                  height="xxsmall"
                />
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="center"
              justify="center"
              direction="row"
              fill="horizontal"
              pad="xsmall"
              flex={false}
              gap="xlarge"
            >
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                direction="column"
                pad="xsmall"
                round="xsmall"
                elevation="xlarge"
                basis="small"
                flex={false}
                background={{ color: `${lvlmints > 3 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Minted 8000 NFT
                </Heading>
                {lvlmints < 4 && (
                  <Paragraph margin="none" textAlign="center">
                    Cost to Mint Updated
                  </Paragraph>
                )}
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                pad="xsmall"
                round="xsmall"
                elevation="xlarge"
                basis="small"
                flex={false}
                background={{ color: `${lvldaos > 2 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  1st Proposal Excecuted
                </Heading>
                <Paragraph margin="none" textAlign="center">
                  Waiting for Next Voting Period
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="start"
              justify="center"
              flex={false}
              direction="row"
              width="100%"
            >
              <StyledBox align="start" justify="start">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  height="xxsmall"
                  pad="xsmall"
                />
                <StyledBox
                  align="center"
                  justify="center"
                  background={{ color: "active-text" }}
                  width="xxsmall"
                  pad="xsmall"
                  animation={{ type: "slideLeft", size: "xlarge" }}
                />
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                direction="column"
                pad="small"
                round="xsmall"
                elevation="xlarge"
                flex={false}
                background={{ color: `${lvlmints > 4 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Minted All NFT
                </Heading>
                <Paragraph margin="none" textAlign="center">
                  10.000 Minted
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox align="end" justify="start">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  height="xxsmall"
                  pad="xsmall"
                />
                <StyledBox
                  align="center"
                  justify="center"
                  background={{ color: "active-text" }}
                  width="xxsmall"
                  pad="xsmall"
                  animation={{ type: "slideRight", size: "xlarge" }}
                />
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="start"
              justify="center"
              direction="row"
              fill="horizontal"
              pad="xsmall"
              gap="none"
              responsive
              flex={false}
              wrap={false}
            >
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                direction="column"
                pad="small"
                round="xsmall"
                elevation="xlarge"
                basis="small"
                background={{ color: `${lvlmints > 4 && "active-text"}` }}
              >
                <Heading margin="none" level="3" textAlign="center">
                  Special NFT MINTED
                </Heading>
                <Paragraph margin="none" textAlign="center">
                  Minter Can Claim Special NFT
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
              <StyledBox align="center" justify="start">
                <StyledBox
                  align="center"
                  justify="center"
                  flex={false}
                  background={{ color: "active-text" }}
                  animation={{ type: "slideDown", size: "xlarge" }}
                  height="xxsmall"
                  pad="xsmall"
                />
                <StyledBox
                  align="center"
                  justify="center"
                  background={{ color: "active-text" }}
                  width="xsmall"
                  pad="xsmall"
                  animation={{ type: "fadeIn", size: "xlarge" }}
                />
              </StyledBox>
              <StyledBox
                align="center"
                justify="center"
                gap="none"
                pad="small"
                round="xsmall"
                elevation="xlarge"
                wrap
                basis="small"
                background={{ color: `${lvlmints > 4 && "active-text"}` }}
              >
                <Heading
                  margin="none"
                  level="3"
                  textAlign="center"
                  size="small"
                >
                  Posptpone Project
                </Heading>
                <Paragraph margin="none" textAlign="center" size="14px">
                  NFT Holder Can Vote to Postpone the Project,
                </Paragraph>
                <Paragraph margin="none" textAlign="center" size="14px">
                  Royalty Donation wil be Distributed to NFT Holder
                </Paragraph>
                <Paragraph margin="none" color="black">
                  Passed
                </Paragraph>
              </StyledBox>
            </StyledBox>
            <StyledBox
              fill="horizontal"
              gap="small"
              margin="medium"
              pad="small"
              elevation="large"
              round="small"
              flex={false}
              align="center"
              justify="center"
            >
              <Heading>Utility Roadmap</Heading>
              <Paragraph textAlign="center">
                - Integrate conceal metadata function in the dapp, so holder can
                conceal and reveal their nft metadata.
              </Paragraph>
              <Paragraph textAlign="center">
                - Bridge to other chain with 1/1 mechanism{" "}
                {"(meaning no duplicate of the token in different chain)."}
              </Paragraph>
            </StyledBox>
            {/* <StyledBox
              direction="column"
              gap="medium"
              pad="small"
              margin={{ bottom: "medium" }}
            >
              <Heading textAlign="center">Whitelist Airdrop</Heading>
              <Paragraph textAlign="center">
                1. Follow our twitter or verify you are follower.{" "}
              </Paragraph>
              <Paragraph textAlign="center">
                {" "}
                2. Tweet quote bellow, change 0x00000... with your address.{" "}
              </Paragraph>
              <Paragraph textAlign="center">
                3. Then copy your url quoted tweet and paste to the url
                validator.{" "}
              </Paragraph>
              <Paragraph textAlign="center">
                4. Make sure your twitter account not private/protected.{" "}
              </Paragraph>
              <Heading level="2" textAlign="center">
                Follow Our Twitter
              </Heading>
              <Button
                label="Follow"
                icon={<Twitter />}
                onClick={() => setIsFollow(true)}
                href="https://twitter.com/intent/user?screen_name=GodSaveOddesa"
                target="_blank"
              />
              <Heading textAlign="center" level="2">
                Quote Tweet
              </Heading>
              <Button
                label="Quote Tweet"
                icon={<Twitter />}
                disabled={!isFollow}
                href={`https://twitter.com/intent/tweet?text=Testing%20API%20for%20DAO%20smart%20contract%20vote%20with%20a%20single%20tweet!%20${ethers.constants.AddressZero}.%20${twitter}&hashtags=${DAOAddress}%2CLOCAL_HOST%2CVOTE2%2CDAO%2CWEB3%2CEthereum%2CBlockhcain`}
                target="_blank"
              />
              <Heading textAlign="center" level="3">
                Paste Your URL Here
              </Heading>

              {isError && (
                <Paragraph textAlign="center" color="status-critical">
                  {errMessage}
                </Paragraph>
              )}
              {isErrorFetch && (
                <Paragraph textAlign="center" color="status-critical">
                  {errorFetch.error}
                </Paragraph>
              )}
              <TextInput
                placeholder="https://twitter.com/{your twitter account}/status....."
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              {success && (
                <Paragraph textAlign="center" color="accent-1">
                  {successMsg.message}
                </Paragraph>
              )}

              {openWL ? undefined : (
                <Button
                  label="Validate"
                  onClick={() => validating()}
                  disabled={!activateButton}
                />
              )}
              <br />
              <Heading textAlign="center" level="2">
                NFT Airdrop
              </Heading>
              <Heading textAlign="center" level="2">
                Check Whitelist Address
              </Heading>
              <Paragraph textAlign="center">
                <b>{totalWhitelist}/100 </b>
                Total Address in the list
              </Paragraph>
              {validAddress && (
                <Paragraph textAlign="center" color="accent-1">
                  The Address is in the list
                </Paragraph>
              )}
              {notEOA && (
                <Paragraph textAlign="center" color="status-critical">
                  Address not valid
                </Paragraph>
              )}
              {claimed && (
                <Paragraph textAlign="center" color="status-critical">
                  Address already claimed
                </Paragraph>
              )}
              {notWhitelist && (
                <Paragraph textAlign="center" color="status-critical">
                  Address is not in the list
                </Paragraph>
              )}
              <TextInput
                placeholder="Wallet Address Here"
                value={valueAddress}
                onChange={(e) => setValueAddress(e.target.value)}
              />
              {openWL && (
                <Button
                  label="Claim NFT"
                  onClick={() => transWL(valueAddress)}
                  disabled={!validAddress}
                />
              )}
            </StyledBox> */}
          </StyledBox>
          <StyledBox
            background={{ color: "active-text" }}
            fill="horizontal"
            border
            flex={false}
          />
        </Layer>
      )}
    </>
  );
};

export default RoadMap;
