import {
  Layer,
  Box,
  Button,
  Markdown,
  Drop,
  Form,
  FormField,
  Paragraph,
  Anchor,
} from "grommet";

import { Close } from "grommet-icons";
import { ethers } from "ethers";
import { useState, useRef, useCallback, useEffect } from "react";
import { ReactComponent as Opensea } from "../assets/Svgs/opensea.svg";

const ModalNFT = ({
  name,
  image,
  thumbImage,
  id,
  pay,
  claimFunc,
  pending,
  submit,
  connect,
  contractAddress,
}) => {
  const initial = {
    address: "",
  };

  const [layer, setLayer] = useState();
  const [showDrop, setShowDrop] = useState();
  const [valid, setValid] = useState(false);
  const [value, setValue] = useState(initial);
  const targetRef = useRef();

  useEffect(() => {
    if (!layer) return;
    if (!pending) return;
  }, [layer]);

  const closeDrop = () => {
    setValue(initial);
    setValid(false);
    setShowDrop(undefined);
  };

  const _isAddress = useCallback((_addr) => {
    try {
      const address = ethers.utils.isAddress(_addr);
      return address;
    } catch (err) {}
  }, []);

  const validate = (addr) => {
    if (_isAddress(addr.address)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <>
      <Box
        align="start"
        justify="center"
        flex="grow"
        direction="column"
        onClick={() => {
          setLayer(true);
        }}
        background={{
          image: `url(${pending ? image : thumbImage})`,
          opacity: `${pending && pay == 0 ? "medium" : undefined}`,
        }}
        height="small"
        width="medium"
        responsive
      >
        {pending && pay == 0 ? (
          <Paragraph size="xxsmall" textAlign="center" fill alignSelf="center">
            NO PENDING PAYMENT
          </Paragraph>
        ) : undefined}
      </Box>
      {layer && (
        <Layer animate modal responsive={false} margin="none" plain>
          <Box
            align="center"
            justify="between"
            direction="column"
            background={{
              image: `url('${pending ? image : thumbImage}')`,
              size: "cover",
              position: "25%",
            }}
            height="100vh"
            width="medium"
            round="large"
          >
            <Box
              align="start"
              justify="center"
              fill="horizontal"
              pad={{
                vertical: "small",
                horizontal: "small",
              }}
            >
              <Button
                icon={<Close />}
                primary
                secondary
                margin={{ left: "xsmall" }}
                onClick={() => setLayer(undefined)}
              />
            </Box>
            <Box
              align="end"
              justify="between"
              direction="column"
              width="100%"
              gap="none"
              pad={{ bottom: "small" }}
              background={{ color: "background" }}
              round={{ corner: "bottom", size: "large" }}
            >
              <Box
                align="center"
                justify="start"
                direction="column"
                fill="horizontal"
                pad="medium"
              >
                <Paragraph>{name}</Paragraph>
                {pending && (
                  <Markdown
                    components={{
                      p: { props: { textAlign: "start" } },
                    }}
                  >
                    {pay}
                  </Markdown>
                )}
              </Box>
              <Box
                justify="between"
                gap="small"
                width="100%"
                direction="row"
                pad="xxxsmall"
                flex={false}
              >
                <Box flex={false} direction="row" pad="small" gap="small">
                  <Anchor
                    href={`${process.env.REACT_APP_OPENSEA_URL}/${contractAddress}/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Opensea />
                  </Anchor>
                  <Box justify="center">
                    <Button
                      label="Transfer"
                      primary
                      type="button"
                      color="active-text"
                      ref={targetRef}
                      onClick={() => setShowDrop(true)}
                    />
                  </Box>
                </Box>
                <Box direction="row" pad="small" gap="small">
                  <Box justify="center">
                    {pending ? (
                      <Button
                        label="Claim"
                        primary
                        type="button"
                        plain={false}
                        disabled={!connect}
                        color="active-text"
                        onClick={() => claimFunc(id)}
                      />
                    ) : (
                      <Button
                        label="Full Image"
                        primary
                        type="button"
                        color="active-text"
                        href={image}
                        justify="center"
                        target="_blank"
                        rel="noreferrer"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {showDrop && (
            <Drop
              align={{ right: "right" }}
              target={targetRef.current}
              onClickOutside={() => closeDrop()}
              onEsc={() => closeDrop()}
            >
              <Box width="30rem" round="medium">
                <Form
                  value={value}
                  validate="change"
                  onChange={(nextValue, { touched }) => {
                    setValue(nextValue);
                    validate(nextValue);
                  }}
                >
                  <FormField
                    label="Wallet Address"
                    name="address"
                    validate={[
                      (address) => {
                        if (!_isAddress(address))
                          return "Please enter valid Wallet Address";
                        return undefined;
                      },
                    ]}
                  />
                  <Box
                    direction="row"
                    justify="between"
                    margin={{ top: "small" }}
                    pad="small"
                    round="small"
                  >
                    <Button label="Cancel" onClick={() => closeDrop()} />

                    <Button
                      label="Transfer"
                      disabled={!valid}
                      primary
                      onClick={() => submit(value.address, id)}
                    />
                  </Box>
                </Form>
              </Box>
            </Drop>
          )}
        </Layer>
      )}
    </>
  );
};

export default ModalNFT;
