import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/destroyed.png";
import img2 from "../assets/Images/doll.jpg";
import img3 from "../assets/Images/cry.png";
import img4 from "../assets/Images/stroller.jpg";
import img5 from "../assets/Images/1.png";

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 80vw;
  display: flex;

  margin: 0 auto;

  @media (max-width: 48em) {
    width: 90vw;
  }
  @media (max-width: 30em) {
    width: 100vw;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;

  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 6;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;
const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 14%;

  backdrop-filter: blur(10px);
  background-color: ${(props) => `rgba(${props.theme.textRgba},0.5)`};
  border-radius: 20px;

  a {
    text-decoration: underline blue;
    color: blue;
  }

  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.6)`};
    border-radius: 20px;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 70%;
  }
`;
const Right = styled.div`
  width: 50%;
  position: relative;
  img {
    width: 100%;
  }

  .img-big-full {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .small-img-1 {
    width: 50%;
    position: absolute;
    right: 25%;
    bottom: 10%;
    object-position: center 40%;
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    bottom: 20%;
    object-position: center 40%;
  }
  .small-img-3 {
    width: 60%;
    position: absolute;
    left: 65%;
    top: 30%;
    object-position: center 40%;
  }

  .small-img-4 {
    width: 40%;
    position: absolute;
    right: 75%;
    top: 15%;
    object-position: center 40%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      object-fit: cover;
    }
    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 40%;
      height: auto;
      left: 60%;
      bottom: 20%;
    }

    .small-img-3 {
      width: 30%;
      height: auto;
      right: 60%;
      top: 20%;
    }

    .small-img-4 {
      width: 45%;
      height: auto;
      left: 90%;
      bottom: 50%;
    }
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      {/* <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      ></Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
        24 February 2022, the beginning of Russian Invasion. Since that day,
        casualties have been high. The death toll continues to rise, and there
        is no end in sight. Families are being forced to flee their homes,
        seeking refuge from the fighting. But even in these safe havens, they
        are not safe from the war. The Russian army endlessly bombards resident
        areas, hospitals, schools, and even firing rockets at bomb shelters
        where the children reside.
        <br />
        <br />
        Death is a constant reality in Ukraine, and there is no end in sight to
        the conflict.
        <br />
        <br />
        The situation is dire, and many children are forced to fend for
        themselves. With no parents or guardians to protect them, they are easy
        prey for exploitation and abuse. UNICEF say,{" "}
        <a
          href="https://news.un.org/en/story/2022/06/1119432"
          target="_blank"
          rel="noreferrer"
        >
          at least two children killed in Ukraine every day.
        </a>
        <br /> */}
      {/* </Left> */}
      <Right>
        {/* <img src={img1} alt="Ukraine Victim" className="img-big-full" />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img2}
          className="small-img-1"
          alt="victim of war"
        />
        <img
          data-scroll
          data-scroll-speed="-2"
          src={img3}
          alt="victim of ukraine"
          className="small-img-2"
        />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img4}
          alt="victim of ukraine"
          className="small-img-3"
        />
        <img
          data-scroll
          data-scroll-speed="2"
          src={img5}
          alt="victim of ukraine"
          className="small-img-4"
        /> */}
      </Right>
    </Section>
  );
};

export default About;
