import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useEffect, useRef, useState, useCallback } from "react";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { ethers, BigNumber } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { AnimatePresence } from "framer-motion";

import { CLOUD, NFT721, MYTOKEN } from "./constant/abi";

import { Grommet, grommet, Layer, Box, Spinner, Heading, Image } from "grommet";
import { deepMerge } from "grommet/utils";

import { Buffer } from "buffer/";

import LoadQuery from "./components/LoadQuery";
import Home from "./sections/Home";
import About from "./sections/About";
import Mint from "./sections/Mint";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import Poem from "./sections/Poem";
import Dapp from "./sections/Dapp";
import Footer from "./sections/Footer";
import Loader from "./components/Loader";
import MinterLayer from "./components/MinterLayer";
import Collection from "./components/Collection";
import TheDao from "./components/TheDao";
import RoadMap from "./components/RoadMap";
import Modals from "./components/Modals";
import ChainNotif from "./components/ChainNotif";
import AuthenticateButton from "./components/AuthenticateButton";
import walletLogo from "./assets/Svgs/wallet-connect.svg";
import Metamask from "./assets/Images/metamaskWallet.png";
import TrustWallet from "./assets/Images/TrustWallet.png";
import Wallet from "./assets/Images/walletwhite.png";

window.Buffer = window.Buffer || Buffer;

const resolveIPFS = (url, root) => {
  if (!url) {
    return url;
  }

  if (!url.includes("ipfs://") || !url) {
    return url;
  }

  return url.replace("ipfs://", root);
};

const NFTAddress = process.env.REACT_APP_NFT;
const backDoor = process.env.REACT_APP_BACK;

const wallet = [
  {
    name: "Metamask",
    image: Metamask,
    priority: 1,
  },
  {
    name: "Trust",
    image: TrustWallet,
    priority: 2,
  },
  {
    name: "Other",
    image: Wallet,
    priority: 999,
  },
];

const one = ethers.constants.One;

const zeroAddress = ethers.constants.AddressZero;

const polygonChain = Number(process.env.REACT_APP_CHAIN_ID);

const formatNumb = (numb) => ethers.utils.formatUnits(numb, "ether");

function App() {
  const containerRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [instance, setInstance] = useState(null);
  const [isConnected, setIsconnected] = useState(false);
  const [openMinter, setOpen] = useState();
  const [coll, setColl] = useState();
  const [news, setNews] = useState([]);
  const [modalDao, openDao] = useState();
  const [modalRoad, openRoad] = useState();
  const [balance, setBalance] = useState(0);
  const [minted, setMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [amount, setAmount] = useState(1);
  const [showImage, setShow] = useState();
  const [isLoading, setLoading] = useState();
  const [showChainId, setShowChain] = useState(false);
  const [toast, setToast] = useState();
  const [isSuccess, setIsSucces] = useState();
  const [price, setPrice] = useState();
  const [lvlmints, setLvlmint] = useState(0);
  const [lvldaos, setLvldao] = useState(0);
  const [imgMints, setImgMint] = useState("");
  const [imgDaos, setImgDao] = useState("");
  const [chooseprovider, setChoose] = useState();
  const [minterImg, setMinterImg] = useState("");
  const [trigger, setTriger] = useState(false);
  const [transHash, setTransHash] = useState(null);
  const [mintWarning, setMintWarning] = useState(false);
  const [nofund, setNofund] = useState(false);
  // const [singleCost, setSingleCost] = useState();

  const rpcProvider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_CONTRACT
  );

  const beProvider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_BE
  );

  const contractBackend = new ethers.Contract(backDoor, CLOUD, beProvider);

  const relayer = new ethers.utils.SigningKey(
    `0x${process.env.REACT_APP_RELAYERS}`
  );

  const contractNFT = new ethers.Contract(NFTAddress, NFT721, rpcProvider);

  const loadImage = async () => {
    try {
      const _imgMint = await contractBackend.seeMintImg();
      setImgMint(_imgMint);
      const cost = await contractNFT.price(one);
      setPrice(formatNumb(cost));
      setLoaded(true);
      setMaxSupply(10000);
      // const news = await ethers.utils.fetchJson(process.env.REACT_APP_NEWS_URL);
      // const items = await Promise.all(
      //   news.map(async (i) => {
      //     let item = {
      //       image: resolveIPFS(i.image, process.env.REACT_APP_STORAGE),
      //       caption: i.caption,
      //       url: i.url,
      //     };
      //     return item;
      //   })
      // );
      // setNews(items);
      const _supply = await contractNFT.totalSupply();
      setMinted(_supply.toNumber());
      const _show = await contractBackend.propImage();
      setShow(_show);

      const _lvlMint = await contractBackend.lvlmint();
      const _lvldaos = await contractBackend.lvldao();
      const _imgDaos = await contractBackend.daoImg();
      const _notRevealed = await contractBackend.notRevealed();
      setLvlmint(_lvlMint);
      setLvldao(_lvldaos);
      setImgDao(_imgDaos);
      setMinterImg(_notRevealed);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  const mintEvent = contractNFT.filters.Transfer(zeroAddress);

  useEffect(() => {
    contractNFT.on(mintEvent, (from, to, id) => {
      setMinted((prev) => prev + 1);
    });

    return () => contractNFT.removeAllListeners(mintEvent);
  }, []);

  const subscribeEvent = (froms, tos) => {
    contractNFT.on(froms, (from, to, id) => {
      setBalance((prev) => prev - 1);
    });

    contractNFT.on(tos, (from, to, id) => {
      setBalance((prev) => prev + 1);
    });
  };

  useEffect(() => {
    if (!account) {
      setBalance(0);
      return;
    }
    const transferFromAccount = contractNFT.filters.Transfer(account);
    const transferToAccount = contractNFT.filters.Transfer(null, account);
    subscribeEvent(transferFromAccount, transferToAccount);

    return () =>
      contractNFT.removeAllListeners(transferToAccount, transferFromAccount);
  }, [account]);

  useEffect(() => {
    if (instance) {
      subscribeProvider(instance);
    }

    return () => unsubscribeProvider(instance);
  }, [instance]);

  useEffect(() => {
    if (account && chainId) {
      setIsconnected(true);
    } else {
      setIsconnected(false);
    }
  }, [account, chainId]);

  useEffect(() => {
    if (!isConnected) return;
    if (chainId !== polygonChain) {
      setShowChain(true);
    } else {
      setShowChain(false);
    }
  }, [isConnected, chainId]);

  const connect = isConnected && chainId === polygonChain;

  const handlePlus = useCallback(() => {
    setAmount(amount + 1);
    if (amount === 48) {
      setAmount(48);
      setMintWarning(true);
    }
  }, [amount]);

  const handleMin = useCallback(() => {
    if (mintWarning) {
      setMintWarning(false);
    }
    if (nofund) {
      setNofund(false);
    }
    setAmount(amount - 1);
    if (amount <= 1) {
      setAmount(1);
    }
  }, [amount, mintWarning]);

  useEffect(() => {
    const id = window.localStorage.getItem("connectWEBNFT");
    if (id === "connected" && window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length) {
            handleSign(false);
          }
        })
        .catch((e) => {
          console.error("error", e);
        });
    }
  }, []);

  const signEthereum = async () => {
    try {
      const _providers = new ethers.providers.Web3Provider(window.ethereum);

      await _providers.send("eth_requestAccounts", []);

      setProvider(_providers);
      if (chooseprovider) {
        setChoose(undefined);
      }
      const accounts = await _providers.listAccounts();
      const bal = await contractNFT.balanceOf(accounts[0]);
      setBalance(bal.toNumber());
      setAccount(accounts[0]);
      const chainIds = await _providers.getNetwork();
      setChainId(chainIds.chainId);
      setInstance(window.ethereum);
      window.localStorage.setItem("connectWEBNFT", "connected");
    } catch (err) {
      console.error(err);
    }
  };

  const signMobile = async () => {
    try {
      const walletConnect = new WalletConnectProvider({
        rpc: {
          1: "https://eth-rpc.gateway.pokt.network",
          56: "https://rpc.ankr.com/bsc",
          polygonChain: "https://rpc-mainnet.matic.quiknode.pro",
          80001: "https://rpc.ankr.com/polygon_mumbai",
          43114: "https://rpc.ankr.com/avalanche",
          250: "https://rpc3.fantom.network",
          8217: "https://klaytn02.fandom.finance",
          42161: "https://arb1.arbitrum.io/rpc",
        },
      });
      await walletConnect.enable();
      const _provider = new ethers.providers.Web3Provider(walletConnect);
      setProvider(_provider);
      if (chooseprovider) {
        setChoose(undefined);
      }
      const accounts = await _provider.listAccounts();
      const _bal = await contractNFT.balanceOf(accounts[0]);
      setBalance(_bal.toNumber());
      setAccount(accounts[0]);
      const chainIds = await _provider.getNetwork();
      setChainId(chainIds.chainId);
      setInstance(walletConnect);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSign = async (state) => {
    if (state === true) {
      await signMobile();
    } else {
      await signEthereum();
    }
  };

  const logout = async () => {
    // if (
    //   instance &&
    //   instance.currentProvider &&
    //   instance.currentProvider.close
    // ) {
    //   await instance.currentProvider.close();
    // }
    // await web3Modal.clearCachedProvider();
    try {
      setIsconnected(false);
      setAccount(null);
      setChainId(null);
      setProvider(null);
      if (instance.isWalletConnect) {
        await instance.disconnect();
        window.localStorage.removeItem("walletconnect");
      } else {
        window.localStorage.removeItem("connectWEBNFT");
      }
      setInstance(null);
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  async function handleAccountsChanged(accounts) {
    try {
      if (accounts.length === 0) {
        return logout().catch((e) => {
          console.error(e);
        });
      } else {
        const _bal = await contractNFT.balanceOf(accounts[0]);
        setBalance(_bal.toNumber());
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const subscribeProvider = async (connection) => {
    if (!connection.on) return;
    try {
      connection.on("connect", (info) =>
        console.log("Wallet Reconnect...", info)
      );
      connection.on("accountsChanged", (acc) => {
        handleAccountsChanged(acc);
      });

      connection.on("chainChanged", (e) => {
        let chainString = e.toString();
        if (chainString.includes("0x")) {
          setChainId(parseInt(chainString, 16));
        } else {
          setChainId(Number(chainString));
        }
      });

      connection.on("disconnect", (e) => {
        console.error(e);
        if (connection.isWalletConnect) {
          logout();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const unsubscribeProvider = async (connection) => {
    try {
      if (connection?.removeListener) {
        connection?.removeListener("connect", (info) =>
          console.log("Wallet Reconnect...", info)
        );
        connection?.removeListener("accountsChanged", (acc) => {
          handleAccountsChanged(acc[0]);
        });

        connection?.removeListener("chainChanged", (e) => {
          let chainString = e.toString();
          if (chainString.includes("0x")) {
            setChainId(parseInt(chainString, 16));
          } else {
            setChainId(Number(chainString));
          }
        });

        connection?.removeListener("disconnect", (e) => {
          console.error(e);
          if (connection.isWalletConnect) {
            logout();
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getGas() {
    let data = {
      maxPriorityFeePerGas: ethers.BigNumber.from(40000000000),
      maxFeePerGas: ethers.BigNumber.from(40000000000),
    };
    try {
      const gas = await (await fetch(process.env.REACT_APP_GAS)).json();
      data = {
        maxPriorityFeePerGas: ethers.utils.parseUnits(
          Math.ceil(gas.fast.maxPriorityFee) + "",
          "gwei"
        ),
        maxFeePerGas: ethers.utils.parseUnits(
          Math.ceil(gas.fast.maxFee) + "",
          "gwei"
        ),
      };
      return data;
    } catch {}
  }

  async function groupTokens(_numb) {
    let group = [];
    for (let i = 0; i < _numb.length; i++) {
      let tokenId = await contractNFT.tokenOfOwnerByIndex(
        NFTAddress,
        BigNumber.from(_numb[i])
      );
      group.push(tokenId);
    }
    return group;
  }

  const claimWhitelist = async (_address, _proof) => {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NFTAddress, NFT721, signer);
    const balanceContract = await contract.balanceOf(NFTAddress);
    const groupIndex = [...Array(balanceContract.toNumber()).keys()];
    const shuffled = ethers.utils.shuffled(groupIndex);
    const sliced = shuffled.slice(0, 5);
    const gas = await getGas();
    setLoading(true);
    try {
      const tokens = await groupTokens(sliced);
      const claim = await contract.whitelistTransfer(_address, _proof, tokens, {
        ...gas,
      });
      setTriger(true);
      setLoading(false);
      const tx = await claim.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      if (_address !== account) return;
    }
  };

  const mint = async () => {
    setLoading(true);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(NFTAddress, NFT721, signer);
    try {
      const amounts = BigNumber.from(amount);
      const prices = await contractNFT.price(amounts);
      const native = await signer.getBalance();
      if (native.lt(prices)) {
        setNofund(true);
        setLoading(false);
        return;
      }
      const gas = await getGas();
      const mint = await nftContract.mintTo(
        "0xeF69A052BA2bD1DcF15b076B54dcC78cc52af866",
        amounts,
        {
          value: prices,
          ...gas,
        }
      );

      setLoading(false);
      setTriger(true);
      const tx = await mint.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setToast("Mint Failed!");
      setIsSucces(false);
      setLoading(false);
    }
  };

  // const getSupply = async (kontrak) => {
  //   try {
  //     let supply = 0;
  //     let _maxSupply = 0;
  //     if (kontrak) {
  //       supply = await kontrak.totalSupply();
  //       _maxSupply = await kontrak.maxSupply();
  //       setMinted(supply.toNumber());
  //       setMaxSupply(_maxSupply.toNumber());
  //     } else {
  //       supply = await contractNFT.totalSupply();
  //       _maxSupply = await contractNFT.maxSupply();
  //       setMinted(supply.toNumber());
  //       setMaxSupply(_maxSupply.toNumber());
  //     }
  //     return [supply, _maxSupply];
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getPrice = (_supply, _maxSupply, _singleCost, _amount) => {
  //   let total = _supply + _amount;
  //   let remain;
  //   const thirty = 0.3 * _maxSupply;
  //   const fifty = _maxSupply / 2;
  //   const eighty = 0.8 * _maxSupply;
  //   if (total <= thirty) {
  //     setPrice(_singleCost * _amount);
  //     return;
  //   }
  //   if (_supply <= thirty && total > thirty) {
  //     remain = thirty - _supply;
  //     setPrice(_singleCost * remain + 2 * _singleCost * (_amount - remain));
  //     return;
  //   }
  //   if (_supply > thirty && total <= fifty) {
  //     setPrice(_singleCost * 2 * _amount);
  //     return;
  //   }
  //   if (_supply <= fifty && total > fifty) {
  //     remain = fifty - _supply;
  //     setPrice(_singleCost * 2 * remain + _singleCost * 3 * (_amount - remain));
  //     return;
  //   }
  //   if (_supply > fifty && total <= eighty) {
  //     setPrice(_singleCost * 3 * _amount);
  //     return;
  //   }
  //   if (_supply <= eighty && total > eighty) {
  //     remain = eighty - _supply;
  //     setPrice(_singleCost * 3 * remain + _singleCost * 4 * (_amount - remain));
  //     return;
  //   }
  //   if (_supply > eighty) {
  //     setPrice(_singleCost * 4 * _amount);
  //   }
  // };

  const customBreakpoints = deepMerge(grommet, {
    global: {
      font: {
        family: "Sirin Stencil",
      },
      breakpoints: {
        xsmall: {
          value: 500,
        },
        small: {
          value: 900,
        },
        medium: undefined,
        middle: {
          value: 3000,
        },
      },
    },
    anchor: { textDecoration: "underline" },
  });

  return (
    <div>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          // ... all available Locomotive Scroll instance options
          smartphone: {
            smooth: true,
            lerp: 0.6,
          },
          tablet: {
            smooth: true,
          },
        }}
        watch={
          [
            //..all the dependencies you want to watch to update the scroll.
            //  Basicaly, you would want to watch page/location changes
            //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
          ]
        }
        containerRef={containerRef}
      >
        <GlobalStyles />
        <ScrollTriggerProxy />

        <ThemeProvider theme={dark}>
          <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>
          <AnimatePresence>
            <main className="App" data-scroll-container ref={containerRef}>
              <Home />
              <About />
              <Mint
                handlePlus={handlePlus}
                handleMin={handleMin}
                amount={amount}
                mint={mint}
                mintWarning={mintWarning}
                nofund={nofund}
                news={news}
                balance={balance}
                connect={connect}
                provider={provider}
                totalPrice={price}
                imgMints={imgMints}
                minted={minted}
              />
              <Poem />
              <Dapp
                minter={setOpen}
                collection={setColl}
                dao={openDao}
                roadmap={openRoad}
              />
              <Footer />
            </main>
          </AnimatePresence>
        </ThemeProvider>
        <Grommet full theme={customBreakpoints} themeMode="dark">
          <AuthenticateButton
            isConnected={isConnected}
            address={account}
            logout={logout}
            setChoose={setChoose}
          />
          <MinterLayer
            open={openMinter}
            onClose={setOpen}
            account={account}
            provider={provider}
            minted={minted}
            maxSupply={maxSupply}
            connect={connect}
            minterImg={minterImg}
            setTriger={setTriger}
            setTransHash={setTransHash}
          />
          <Collection
            coll={coll}
            setColl={setColl}
            provider={provider}
            account={account}
            minted={minted}
            maxSupply={maxSupply}
            balance={balance}
            connect={connect}
            setTriger={setTriger}
            setTransHash={setTransHash}
          />
          <TheDao
            provider={provider}
            account={account}
            modal={modalDao}
            openDao={openDao}
            showPlaceholder={showImage}
            balance={balance}
            supply={minted}
            connect={connect}
            imgDaos={imgDaos}
            contractBackend={contractBackend}
            relayer={relayer}
            setTriger={setTriger}
            setTransHash={setTransHash}
          />
          <RoadMap
            lvlmints={lvlmints}
            lvldaos={lvldaos}
            modalRoad={modalRoad}
            openRoad={openRoad}
            relayer={relayer}
            claimWhitelist={claimWhitelist}
            contractNFT={contractNFT}
            minted={minted}
          />
          <LoadQuery
            trigger={trigger}
            setTriger={setTriger}
            transHash={transHash}
            setTransHash={setTransHash}
          />
          {isLoading && (
            <Layer
              full
              modal
              background={{ color: "light-3", opacity: "strong" }}
              position="center"
              animate={false}
            >
              <Box fill align="center" justify="center">
                <Spinner size="xlarge" color="active-text" />
              </Box>
            </Layer>
          )}
          {showChainId && <ChainNotif />}
          <Modals toast={toast} setToast={setToast} success={isSuccess} />
          {chooseprovider && (
            <Layer
              animate
              onClickOutside={() => setChoose(undefined)}
              responsive={false}
              modal
            >
              <Box
                align="start"
                justify="center"
                pad="small"
                direction="column"
                round="large"
                gap="small"
                background="status-unknown"
              >
                <Box
                  align="center"
                  justify="center"
                  alignSelf="end"
                  fill="horizontal"
                >
                  <Heading
                    textAlign="center"
                    size="1.5rem"
                    align="center"
                    margin="none"
                  >
                    Connect Wallet
                  </Heading>
                </Box>
                <Box
                  fill="horizontal"
                  direction="row"
                  gap="large"
                  justify="evenly"
                >
                  {window.ethereum &&
                    wallet.map((wallets, i) => (
                      <Box
                        key={i}
                        align="center"
                        direction="column"
                        onClick={() => handleSign(false)}
                        background="black"
                        pad="small"
                        round="small"
                        basis="small"
                        gap="small"
                        responsive={false}
                      >
                        <Heading textAlign="center" size="1rem">
                          {wallets.name}
                        </Heading>
                        <img
                          src={wallets.image}
                          alt={wallets.name}
                          style={{ width: "2.5rem" }}
                        />
                      </Box>
                    ))}
                </Box>
                <Box
                  round="small"
                  pad="1rem"
                  background="black"
                  direction="column"
                  onClick={() => handleSign(true)}
                  align="center"
                  alignSelf="center"
                  margin={{ top: "0.5rem" }}
                  responsive={false}
                >
                  <Heading
                    size="1rem"
                    textAlign="center"
                    margin={{ bottom: "medium" }}
                  >
                    Mobile
                  </Heading>
                  <Image width="100%" height="50%" src={walletLogo} />
                </Box>
              </Box>
            </Layer>
          )}
        </Grommet>
      </LocomotiveScrollProvider>
    </div>
  );
}

export default App;
