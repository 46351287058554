import { Layer, Box, Spinner, Paragraph, Anchor } from "grommet";
import { Checkmark } from "grommet-icons";
import { useEffect } from "react";

const LoadQuery = ({ trigger, setTriger, transHash, setTransHash }) => {
  useEffect(() => {
    if (transHash === null) return;
    setTimeout(() => {
      setTriger(undefined);
      setTransHash(null);
    }, 5000);
    return () => {};
  }, [transHash]);

  const handleButton = () => {
    if (transHash) {
      setTransHash(null);
    }
    setTriger(false);
  };

  return (
    <>
      {trigger && (
        <Layer
          animate
          modal={false}
          position={"bottom-right"}
          plain
          responsive={false}
          margin={{ bottom: "12rem" }}
          onClickOutside={() => handleButton()}
        >
          <Box
            align="center"
            justify="between"
            basis="xxsmall"
            background={{ color: "accent-1" }}
            width="65vmin"
            direction="row"
            gap="medium"
            responsive={false}
            round={{ size: "small", corner: "left" }}
            pad="small"
          >
            {transHash ? (
              <Checkmark color="status-ok" />
            ) : (
              <Spinner
                border={[
                  { side: "all", color: "transparent", size: "small" },
                  { side: "horizontal", color: "brand", size: "small" },
                ]}
              />
            )}
            <Box direction="row" flex={false} pad={{ right: "small" }}>
              {transHash && transHash.length > 20 ? (
                <>
                  <Paragraph size="small" maxLines={2} responsive={false}>
                    Success, Your Transaction Hash{" "}
                    <Anchor
                      label={transHash}
                      target="_blank"
                      href={`${process.env.REACT_APP_EXPLORER_TX}/${transHash}`}
                    />
                  </Paragraph>
                </>
              ) : (
                <>
                  <Paragraph size="small">
                    Waiting for Block Confirmation...
                  </Paragraph>
                </>
              )}
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default LoadQuery;
