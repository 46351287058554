import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import image1 from "../assets/Images/external-icon.png";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const blockUrl = process.env.REACT_APP_EXPLORER;
const NFTAddress = process.env.REACT_APP_NFT;
const RoyaltyAddress = process.env.REACT_APP_ROYALTY;

const Btn = styled.button`
  background-color: rgba(54, 94, 227, 0.4);
  border: 1px solid
    ${({ buynow }) =>
      buynow ? "hsla(40, 72%, 60%, 1)" : "hsla(0, 0%, 0%, 0.4)"};
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => props.theme.text};
  outline: none;
  text-shadow: 0.1rem 0.1rem 0.5rem hsla(0, 0%, 0%, 0.5);
  letter-spacing: 0.1rem;
  border-radius: 0.5rem;
  font-family: "Sirin Stencil";

  user-select: pointer;
  padding: 1vh 1vmax;
  transition: all 0.1s ease-in;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.0125),
    0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: ${({ buynow }) => (buynow ? "0.5vh" : undefined)};

  ::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: ${({ buynow }) =>
      buynow ? "hsla(246, 80%, 17%, 1)" : "hsla(76, 55%, 43%, 1)"};
    ${({ buynow }) => buynow && `transform: translateY(-3px)`}
  }

  &:active {
    background-color: ${({ buynow }) =>
      buynow ? "hsla(40, 72%, 35%, 1)" : "hsla(347, 49%, 26%, 1)"};
  }
`;

const SignInBtn = styled(Btn)`
  text-decoration: none;
  background: none;
  background-color: hsla(189, 85%, 28%, 1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.0125),
    0 1px 1px rgba(0, 0, 0, 0.05);
  border-bottom-width: 0.5rem;
  padding: 0.3vh 2.5vmax;
  border-radius: 1rem;

  &:hover {
    background-color: hsla(189, 85%, 32%, 1);
  }

  &:active {
    border-bottom-width: 0.1rem;
    border-top-width: 0.5rem;
  }
`;

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  position: relative;

  background-color: ${(props) => props.theme.grey};
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: absolute;
  top: -16px;
  right: 5%;
  z-index: 11;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Item = styled(motion.div)`
  margin-right: 12rem;

  padding: 5rem;

  border-radius: 20px;

  background-image: ${(props) => `url(${props._image})`};
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  @media (max-width: 48em) {
    width: 12rem;
    padding: 3rem;
  }
`;

const MintItem = styled(Item)`
  justify-content: center;
  margin: 0px;
  width: 11vmin;
  height: 15vh;
  background-position: 25%;
  background-repeat: no-repeat;

  @media (max-height: 500px) {
    height: 3vmin;
    width: 1vmax;
  }
`;

const Left = styled.div`
  max-width: 20rem;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  min-height: 100vh;
  z-index: 5;

  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  will-change: transform;

  a {
    text-decoration: underline;
    color: #e8e82a;
    font-size: ${(props) => props.theme.fontmd};
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: ${(props) => props.theme.fontmd};
    font-weight: 200;
    width: 80%;
    margin: 0.8vh auto;
    text-align: center;
  }

  .totalminted {
    margin: 0;
  }

  @media (max-width: 64em) {
    width: 40%;
    p {
      font-size: ${(props) => props.theme.fontmd};
    }
    .sc {
      font-size: ${(props) => props.theme.fontlg};
    }
  }

  @media (max-width: 48em) {
    width: 40%;
    p {
      font-size: ${(props) => props.theme.fontsm};
    }
    .sc {
      font-size: ${(props) => props.theme.fontlg};
    }
  }

  @media (max-width: 30em) {
    width: 40%;
    p {
      font-size: ${(props) => props.theme.fontxs};
    }
    .sc {
      font-size: ${(props) => props.theme.fontmd};
    }
    @media (max-height: 500px) {
      width: 40%;
      p {
        font-size: ${(props) => props.theme.fontxs};
      }
      .sc {
        font-size: ${(props) => props.theme.fontmd};
      }
    }
  }
`;
const Right = styled.div`
  position: absolute;
  left: 35%;
  padding-left: 5%;
  min-height: 100vh;
  overflow-x: hidden;

  background-color: ${(props) => props.theme.grey};

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    width: 5rem;
    margin: 0 2rem;
  }

  @media (max-height: 500px) {
    align-items: start;
  }
`;

const Caption = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  align-content: end;

  overflow: hidden;
  width: 100%;
  height: 100%;

  border-radius: 20px;
  will-change: transform;

  h1 {
    text-decoration: underline;

    display: inline-block;
    width: fit-content;
    font-weight: 500;
    color: white;
    text-align: center;
    cursor: pointer;
    text-overflow: ellipsis;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
`;

const AmountContainer = styled.div`
  display: flex;
  direction: row;
  font-size: ${(props) => props.theme.fontxl};
  margin-bottom: 1vh;
  padding: 10vh auto;

  p {
    margin: auto 2vmax;
  }

  @media (max-width: 64em) {
    p {
      font-size: ${(props) => props.theme.fontlg};
    }
  }

  @media (max-width: 48em) {
    p {
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  @media (max-width: 30em) {
    p {
      font-size: ${(props) => props.theme.fontsm};
    }
  }
`;

const NewElement = ({ title = "", _image, url = "" }) => {
  return (
    <Item
      initial={{ filter: "grayscale(100%)" }}
      whileInView={{ filter: "grayscale(0%)" }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false, amount: "all" }}
      _image={_image}
    >
      <Caption>
        <a href={url} target="_blank" rel="noreferrer">
          <h1>
            {`${title}  `} <img src={image1} alt="war-ukraine" />
          </h1>
        </a>
      </Caption>
    </Item>
  );
};

const formatMinted = (num) => {
  if (num < 1000 || num > 9999) {
    return num;
  } else {
    const str = num.toString();
    const arrStr = str.split("").splice(1, 0, ".");
    return arrStr.join("");
  }
};

const Mint = ({
  handlePlus,
  handleMin,
  amount,
  mint,
  news,
  balance,
  connect,
  totalPrice,
  imgMints,
  mintWarning,
  nofund,
  minted,
  provider,
}) => {
  const [notConnect, setNotConnect] = useState(false);
  const handelBtn = () => {
    if (connect) {
      mint();
    } else {
      setNotConnect(true);
    }
  };

  useEffect(() => {
    if (!connect) return;
    if (notConnect) {
      setNotConnect(false);
    }
  }, [notConnect]);
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
  const horizontalRef = useRef(null);

  useLayoutEffect(() => {
    let element = ref.current;
    let scrollingElement = horizontalRef.current;

    let pinWrapWidth = scrollingElement.offsetWidth;

    let t1 = gsap.timeline();

    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: pinWrapWidth,
          scroller: ".App", // locomotive element
          scrub: true,
          pin: true,
          id: "mintnft",

          // markers:true,
        },
        // we have to increase scrolling height of this section same as the scrolling element width
        height: `${scrollingElement.scrollWidth}px`,
        ease: "none",
      });

      // Horizontal Scrolling
      t1.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: pinWrapWidth,
          scroller: ".App", // locomotive element
          scrub: true,
          id: "mintnft",
          // markers:true,
        },
        // we have to increase scrolling height of this section same as the scrolling element width
        x: -pinWrapWidth,
        ease: "none",
      });
      ScrollTrigger.refresh();
    }, 1000);
    return () => {
      // Let's clear instances
      const triggerKill = ScrollTrigger.getById("mintnft");
      if (triggerKill) {
        triggerKill.kill();
      }
    };
  }, []);

  return (
    <Section ref={ref} id="mintnft">
      <Title data-scroll data-scroll-speed="-1">
        We Never Forget!
      </Title>
      <Left>
        <p className="sc">
          NFT Contract Address <br />
          <a
            href={`${blockUrl}/${process.env.REACT_APP_NFT}`}
            target="_blank"
            rel="noreferrer"
          >
            {NFTAddress}
          </a>
        </p>
        <p className="totalminted">Total Minted :</p>
        <p>{formatMinted(minted)} / 10.000</p>
        <MintItem _image={imgMints} />
        {mintWarning ? (
          <p style={{ color: "red" }}>Maximum mint per transaction reached</p>
        ) : (
          <p>{totalPrice} Matic / NFT</p>
        )}
        <AmountContainer>
          <SignInBtn type="button" onClick={() => handleMin()}>
            -
          </SignInBtn>
          <p>{amount}</p>
          <SignInBtn type="button" onClick={() => handlePlus()}>
            +
          </SignInBtn>
        </AmountContainer>
        {nofund ? (
          <p style={{ color: "red", fontSize: "1rem" }}>Insufficient funds</p>
        ) : undefined}
        {notConnect && (
          <p style={{ color: "red", fontSize: "1rem" }}>
            Connect Your Wallet First or Mint with ETH or Fiat
          </p>
        )}
        <Btn type="button" buynow onClick={() => handelBtn()}>
          {balance === 0 ? "Mint" : "Mint Again"}
        </Btn>
        <Link to={`/minting/${amount}`}>
          <Btn type="button" buynow>
            Mint With ETH/Fiat
          </Btn>
        </Link>
        <p>
          80% of royalties are for donations, see our verified royalty splitter
          smart contract address
        </p>
        <p className="sc">
          <a
            href={`${blockUrl}/${process.env.REACT_APP_ROYALTY}`}
            target="_blank"
            rel="noreferrer"
          >
            {RoyaltyAddress}
          </a>
        </p>
      </Left>
      <Right ref={horizontalRef}></Right>
    </Section>
  );
};

export default Mint;
