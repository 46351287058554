import { useState } from "react";
import { Tip, Text, Box, Button, Layer, Paragraph } from "grommet";

import { Next, Logout } from "grommet-icons";

const AuthenticateButton = ({ address, isConnected, logout, setChoose }) => {
  const [showAddr, setShowAdrr] = useState(false);
  return (
    <Layer
      responsive={false}
      modal={false}
      plain
      position={"bottom-left"}
      margin={{ bottom: "40vh", left: `${!isConnected && "10vw"}` }}
    >
      {!isConnected ? (
        <Box
          background={{ color: "rgba(242, 242, 70, 0.9)" }}
          round="large"
          pad={{ horizontal: "small", vertical: "xsmall" }}
          responsive={false}
          elevation="xlarge"
          hoverIndicator={true}
          border={{ color: "rgba(84, 87, 199, 0.9)", size: "medium" }}
          onClick={() => setChoose(true)}
        >
          <Paragraph color="white" margin="none">
            Connect Wallet
          </Paragraph>
        </Box>
      ) : !showAddr ? (
        <Box
          background={{ color: "status-unknown" }}
          pad={{ right: "small", vertical: "small" }}
          round={{ size: "medium", corner: "right" }}
          elevation="large"
          onClick={() => setShowAdrr(true)}
          animation={{ type: "slideRight", size: "xlarge", delay: 50 }}
          border={{ color: "#f2d705", size: "small", side: "horizontal" }}
        >
          <Next size="30px" color="white" />
        </Box>
      ) : (
        <Box
          background="status-unknown"
          pad="small"
          round={{ size: "medium", corner: "right" }}
          elevation="large"
          direction="row"
          gap="medium"
          animation={{ type: "slideRight", size: "xlarge", delay: 50 }}
          border={{ color: "status-ok", size: "medium" }}
        >
          <Tip
            content="Log Out"
            dropProps={{
              background: "status-unknown",
              round: "medium",
            }}
          >
            <Button
              icon={<Logout color="white" />}
              margin="none"
              color="rgba(70, 87, 242, 0.5)"
              primary
              onClick={() => logout()}
            />
          </Tip>
          <Box
            width="15vmax"
            alignSelf="center"
            onClick={() => setShowAdrr(false)}
          >
            <Text size="medium" weight="bold" truncate>
              {address}
            </Text>
          </Box>
        </Box>
      )}
    </Layer>
  );
};

export default AuthenticateButton;
