//set time after execute

import { useCallback, useEffect, useState } from "react";
import {
  Layer,
  Box,
  Button,
  Heading,
  Paragraph,
  Tabs,
  Tab,
  Grid,
  NameValueList,
  NameValuePair,
  DataChart,
  Text,
  Carousel,
  Anchor,
  Image,
  Spinner,
  Form,
  FormField,
} from "grommet";
import styled from "styled-components";
import {
  Close,
  Article,
  StatusGood,
  Cluster,
  BarChart,
  Edit,
} from "grommet-icons";

import { DAO, ROYALTY } from "../constant/abi";

import { BigNumber, ethers } from "ethers";
import SubmitProposal from "./SubmitProposal";
import CountdownTimer from "./CountDownTimer";
import Modals from "./Modals";

const DAOAddress = process.env.REACT_APP_DAO;
const RoyaltyAddress = process.env.REACT_APP_ROYALTY;
const WETHAddress = process.env.REACT_APP_WETH;

const endPoint = process.env.REACT_APP_BACKEND_URL;

const StyledBox = styled(Box)`
  ::-webkit-scrollbar-track {
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(136, 136, 136, 0.4);
  }
`;

const resState = {
  abstain: 1000,
  yay: 3414,
  nay: 5313,
  postpone: 4352,
};

const recState = {
  title: "",
  address: "",
  image: "",
  url: "",
  desc: "",
  royal: "",
  royalNative: "",
  accum: "",
  accumNative: "",
};

const propsalState = {
  name: "No New Proposal Submited",
  title: "",
  desc: "",
  url: "",
  image: "",
  address: "",
};

const results = (_result) => {
  if (_result === 0) return "Project Pending";
  if (_result === 1) return "Project Accepted";
  if (_result === 2) return "Project Rejected";
  if (_result === 3) return "Project Postponed";
};

const STORAGE = process.env.REACT_APP_STORAGE;

const resolveIPFS = (url) => {
  if (!url) {
    return url;
  }

  if (!url.includes("ipfs://") || !url) {
    return url;
  }

  return url.replace("ipfs://", STORAGE);
};

const timeVote = (time, delay) => time * 1000 + delay * 24 * 60 * 60 * 1000;

const loadStage = (_stage) => {
  if (_stage === 0) {
    return "Voting END";
  }
  if (_stage === 1) {
    return "Starting";
  }
  if (_stage === 2) {
    return "Voting Period";
  }
  if (_stage === 3) {
    return "Counting Result";
  }
};

const initial = {
  address: "",
};

const gasUrl = process.env.REACT_APP_GAS;

const blockUrl = process.env.REACT_APP_EXPLORER;

const formatGas = (numb) =>
  ethers.utils.parseUnits(Math.ceil(numb) + "", "gwei");

const fallbackGas = ethers.BigNumber.from(40000000000);

const formatNumb = (numb) => ethers.utils.formatEther(numb);

const TheDao = ({
  modal,
  openDao,
  provider,
  account,
  balance,
  supply,
  connect,
  imgDaos,
  showPlaceholder,
  contractBackend,
  relayer,
  setTriger,
  setTransHash,
}) => {
  const [index, setIndex] = useState(0);
  const [layerAbs, setLayerAbs] = useState();
  const [layerYay, setLayerYay] = useState();
  const [layerNay, setLayerNay] = useState();
  const [layerKill, setLayerKill] = useState();
  const [layerDelegate, setLayerDelegate] = useState();
  const [stage, setStage] = useState();
  const [time, setTime] = useState(0);
  const [pendingProposal, setPendingProps] = useState([]);
  const [votingWeight, setVotingWeight] = useState(0);
  const [votingUnit, setVotingUnit] = useState(0);
  const [result, setResult] = useState();
  const [block, setBlock] = useState();
  const [res, setRes] = useState(resState);
  const [recDetail, setRecDetail] = useState(recState);
  const [pendingCount, setPendingCount] = useState(0);
  const [proposals, setProposal] = useState(propsalState);
  const [isMetTotal, setMetTotal] = useState();
  const [metReq, setMetReq] = useState();
  const [toast, setToast] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [isLoading, setLoading] = useState(false);
  const [hashing, setHash] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [delegatedUnit, setDelegatedUnit] = useState(0);
  const [valid, setValid] = useState(false);
  const [value, setValue] = useState(initial);
  const [isDelegating, setIsDelegating] = useState();
  const [queryNetwork, setQuery] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [delegateTo, setDelegateTo] = useState();
  const [currentUnit, setCurrentUnit] = useState(0);
  const [postpone, setPostpone] = useState(false);
  const [oldProps, setOldProps] = useState(0);
  const [countingDay, setCountingDays] = useState(0);
  const [nextVoting, setNextVoting] = useState(0);
  // const [days, setDays] = useState(0)
  // const [pastBlocks, setPastBlocks] = useState(false)

  const rpcProvider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_CONTRACT
  );

  const contractDao = new ethers.Contract(DAOAddress, DAO, rpcProvider);

  const contractRoyalty = new ethers.Contract(
    RoyaltyAddress,
    ROYALTY,
    rpcProvider
  );

  useEffect(() => {
    init(0, account, stage);
  }, [account, stage]);

  useEffect(() => {
    fetchStage();
    getNextVoting();
    fetchRecDetail();
  }, []);

  const getNextVoting = async () => {
    const beBlock = await rpcProvider.getBlockNumber();
    const _unit = await contractDao.totalVotingUnit(
      BigNumber.from(beBlock - 1)
    );
    setNextVoting(_unit.toNumber());
  };

  const _dao = async () => {
    if (!provider) return;
    const signer = await provider.getSigner();
    return new ethers.Contract(DAOAddress, DAO, signer);
  };

  const onActive = (nextIndex) => setIndex(nextIndex);

  async function init(numb, _account, _stage) {
    setQuery(true);
    try {
      onActive(numb);
      const _pendingCount = await contractDao.getPendingData();
      await loadSubmit(_pendingCount);
      setOldProps(_pendingCount.toNumber());
      const pendingExec = await contractDao.totalPendingExecuted();
      const total = _pendingCount.toString() - pendingExec.toString();
      setPendingCount(total);
      const _ismet = await contractDao.voteEqualTotal();
      setMetTotal(_ismet);
      const getLen = await contractDao.getBlockLength();

      let timeDelay;
      if (getLen.toString() > 0) {
        const _block = await contractDao.getBlock();
        setBlock(_block);
        await loadVote(_block, _account, _stage);

        const _result = await contractDao.voteResult(_block);
        setResult(_result);
        const _time = await contractDao.executeStamp(_block);
        timeDelay = timeVote(_time.toNumber(), 7);
        setTime(timeDelay);
        const propStart = await contractDao.propsStamp();
        const votingEnd = await contractDao.timeSubmit(propStart);
        setCountingDays(votingEnd.toNumber());
        await loadFirst(_account, _block);
        await loadDetail(_block, _account);
        await loadVotigResult(_block);
      } else {
        const _time = await contractDao.daoStart();
        timeDelay = timeVote(_time, 60);
        setTime(timeDelay);
      }
    } catch (error) {
      console.error(error);
      setQuery(false);
    } finally {
      setQuery(false);
    }
  }

  const fetchRecDetail = async () => {
    const recAddr = await contractDao.receiveCharity();
    const _isPostpone = await contractDao.isProjectKilled();
    setPostpone(_isPostpone);

    let bytesDonation = "";
    if (_isPostpone) {
      bytesDonation = await contractDao.nftBaseMetadata();
    } else {
      bytesDonation = await contractDao.proposalAccepted();
    }
    try {
      const _totalReceived = await contractRoyalty["released(address,uint256)"](
        WETHAddress,
        BigNumber.from(2)
      );
      const _totalReceivedNative = await contractRoyalty["released(uint256)"](
        BigNumber.from(2)
      );
      const share = await contractRoyalty.shares(recAddr);
      const _pendingRoyalty = await contractRoyalty[
        "releasable(address,address)"
      ](WETHAddress, recAddr);
      const _pendingRoyaltyNative = await contractRoyalty[
        "releasable(address)"
      ](recAddr);
      const _pending = share.toNumber() !== 0 && _pendingRoyalty[1];
      const _pendingNative = share.toNumber() !== 0 && _pendingRoyaltyNative[1];
      setRecDetail((prevstate) => ({
        ...prevstate,
        address: recAddr,
        royal: formatNumb(_totalReceived),
        royalNative: formatNumb(_totalReceivedNative),
        accum: share.toNumber() === 0 ? 0 : formatNumb(_pending),
        accumNative: share.toNumber() === 0 ? 0 : formatNumb(_pendingNative),
      }));
      const _link = await contractBackend.hashProposal(bytesDonation);
      const link = `${STORAGE}${_link.metadataProposal}`;
      const meta = await (await fetch(link)).json();
      setRecDetail((prevstate) => ({
        ...prevstate,
        title: meta.title,
        image: `${resolveIPFS(meta.image)}`,
        url: meta.url,
        desc: meta.description,
      }));
    } catch (err) {
      const datas = await (
        await fetch(`${endPoint}/api/cid/${bytesDonation}`)
      ).json();
      setRecDetail((prevstate) => ({
        ...prevstate,
        title: datas.title,
        url: datas.url,
        desc: datas.description,
      }));
    }
  };

  const loadDetail = async (blok, _account) => {
    try {
      if (_account && blok) {
        const _delegate = await contractDao.getTotalDelegate(_account, blok);
        setDelegatedUnit(_delegate.toNumber());
        const hasdeleg = await contractDao.ifDelegated(_account, blok);
        setIsDelegating(hasdeleg);
        const _hasVoted = await contractDao.alredyVoted(blok, _account);
        setHasVoted(_hasVoted);
        const _delegateTo = await contractDao.getDelegatedTo(_account);
        setDelegateTo(_delegateTo);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchStage = async (state) => {
    const _stage = await contractDao.stage();
    setStage(_stage);

    return _stage;
  };

  const loadFirst = async (_account, _block) => {
    try {
      if (!_account) return;
      const _metReq = await contractDao.submitReq(_account, _block);
      setMetReq(_metReq);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProposal = useCallback(
    async (blok) => {
      try {
        const _proposal = await contractDao.proposal(blok);
        const address = _proposal.charityReceiver;
        const isPending = _proposal.end;
        const isExecuted = _proposal.executed;
        const digestHex = _proposal.proposalDNA;
        let cid = "";

        cid = await contractBackend.getCID(digestHex);
        if (cid.length === 0) {
          const _temp = await ethers.utils.fetchJson(
            `${endPoint}/api/cid/${digestHex}`
          );
          const temp = JSON.parse(_temp);
          setProposal({
            ...proposals,
            name: temp.name,
            title: temp.title,
            desc: temp.description,
            url: temp.url,
            address: address,
            isEnding: isPending,
            executed: isExecuted,
          });
        } else {
          const link = `${STORAGE}${cid}`;

          const meta = await ethers.utils.fetchJson(link);

          setProposal({
            ...proposals,
            name: meta.name,
            title: meta.title,
            desc: meta.description,
            url: meta.url,
            image: `${resolveIPFS(meta.image)}`,
            address: address,
            isEnding: isPending,
            executed: isExecuted,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [proposals]
  );

  const loadVote = async (blok, _account, _stage) => {
    try {
      if (!blok) return;
      const _unit = await contractDao.totalVotingUnit(blok);
      setVotingUnit(_unit.toNumber());
      if (!_account) return;
      const _current = await contractDao.currentVoteOwned(_account, blok);
      setCurrentUnit(_current.toNumber());
      const _weight = await contractDao.voteOwned(_account, blok);
      setVotingWeight(_weight.toNumber());
      if (_stage === 1) return;
      await fetchProposal(blok);
    } catch (err) {
      console.error(err);
    }
  };

  const loadVotigResult = useCallback(
    async (blok) => {
      try {
        const _votes = await contractDao.choice(blok);
        setRes({
          ...res,
          abstain: _votes.abstain.toNumber(),
          yay: _votes.yay.toNumber(),
          nay: _votes.nay.toNumber(),
          postpone: _votes.kill.toNumber(),
        });
      } catch (error) {
        console.error("no proposal submitted");
      }
    },
    [res]
  );

  const loadSubmit = useCallback(
    async (_countOld) => {
      try {
        if (_countOld === 0) return;
        const pendingId = await contractDao.allPending();
        const getAll = await Promise.all(
          pendingId.map(async (i, pendingIndex) => {
            const data = await contractDao.proposal(i);

            const hash = data.proposalDNA;
            const link = await contractBackend.hashProposal(hash);
            const url = `${STORAGE}${link.metadataProposal}`;

            const metadata = await ethers.utils.fetchJson(url);

            let item = {
              pendingIndex: BigNumber.from(pendingIndex),
              id: i.toString(),
              title: metadata.title,
              desc: metadata.description,
              url: metadata.url,
              isOld: data.executed,
              recevierAddress: data.charityReceiver,
            };
            return item;
          })
        );
        setPendingProps(getAll);
      } catch (err) {
        console.error(err);
      }
    },
    [pendingProposal]
  );

  async function getGas(url) {
    let data = {
      maxPriorityFeePerGas: fallbackGas,
      maxFeePerGas: fallbackGas,
    };
    try {
      const gas = await (await fetch(url)).json();
      data = {
        maxPriorityFeePerGas: formatGas(gas.fast.maxPriorityFee),
        maxFeePerGas: formatGas(gas.fast.maxFee),
      };
      return data;
    } catch {}
  }

  const enterVote = async (_vote) => {
    if (!provider) return;
    setLoading(true);
    setQuery(true);
    const voting = BigNumber.from(_vote);
    try {
      const gas = await getGas(gasUrl);
      const dao = await _dao();
      const _enter = await dao.vote(voting, { ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await _enter.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setQuery(false);
      setToast(
        "You can't cast vote twice in row per ID or you already delegated your vote"
      );
    } finally {
      setQuery(false);
      await loadVotigResult(block);
      await loadDetail(block, account);
    }
  };

  const killVote = async () => {
    if (!provider) return;
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas(gasUrl);
      const dao = await _dao();
      const _enter = await dao.killProject({ ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await _enter.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setQuery(false);
      setToast(
        "You can't cast vote twice per proposal ID or you already delegated your vote"
      );
    } finally {
      await loadVotigResult(block);
      await loadDetail(block, account);
      setQuery(false);
    }
  };

  const count = async () => {
    if (!provider) return;
    setQuery(true);
    setLoading(true);
    const dao = await _dao();
    try {
      const gas = await getGas(gasUrl);
      const _count = await dao.countVote({ ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await _count.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
      await fetchStage();
    } catch (err) {
      console.error(err);
      setLoading(false);
      setQuery(false);
    } finally {
      const _result = await dao.voteResult(block);
      setResult(_result);
      setQuery(false);
    }
  };

  const executeProps = async () => {
    if (!provider) return;
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas(gasUrl);
      const dao = await _dao();
      const _execute = await dao.execute({ ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await _execute.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setToast("Executing Proposal Result Failed, Try Again Later");
      setIsSuccess(false);
      setQuery(false);
    } finally {
      setToast("Executing Proposal Result Success");
      setIsSuccess(true);
      await fetchStage();
      setQuery(false);
    }
  };

  const startProps = async () => {
    if (!provider) return;
    setLoading(true);
    try {
      setQuery(true);
      const dao = await _dao();
      const gas = await getGas(gasUrl);
      const _start = await dao.stageProposal({ ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await _start.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setToast("Start DAO Stage Failed");
      setIsSuccess(false);
      setQuery(false);
    } finally {
      await fetchStage();
      setQuery(false);
    }
  };

  const delegateVote = async (address) => {
    if (!provider) return;
    setLoading(true);
    setQuery(true);
    try {
      const dao = await _dao();
      const gas = await getGas(gasUrl);
      const deleg = await dao.delegateSubmit(address, { ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await deleg.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      if (stage !== 1) {
        setToast("Not on the right stage");
      }
      if (isDelegating) {
        setToast(
          "Can't delegating vote twice, please wait until next voting period"
        );
      } else {
        setToast(
          "Delegate Voting Unit Failed or Address you delegating are not NFT holder"
        );
      }
      setIsSuccess(false);
      setLoading(false);
      setQuery(false);
    } finally {
      setValue(initial);
      await loadDetail(block, account);
      setQuery(false);
    }
  };

  const submitPending = async (_block) => {
    if (!provider) return;
    setLoading(true);
    setQuery(true);
    try {
      const dao = await _dao();
      const gas = await getGas(gasUrl);

      const submit = await dao.pendingEntry(_block, { ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await submit.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setToast("Submiting Proposal Failed");
      setIsSuccess(false);
      setQuery(false);
    } finally {
      await fetchStage();
      setQuery(false);
    }
  };

  const _isAddress = useCallback((_addr) => {
    try {
      const address = ethers.utils.isAddress(_addr);
      return address;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const validate = (addr) => {
    if (_isAddress(addr.address)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const placeholder = !recDetail.image
    ? imgDaos
    : showPlaceholder
    ? imgDaos
    : recDetail.image;

  const handleDistribute = async () => {
    const signer = await provider.getSigner();
    const contracts = new ethers.Contract(RoyaltyAddress, ROYALTY, signer);
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas(gasUrl);
      const _distribute = await contracts.distributeDonationERC20(WETHAddress, {
        ...gas,
      });
      setLoading(false);
      setTriger(true);
      const tx = await _distribute.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (error) {
      console.error(error);
      setToast("Distribute Donation Fail");
      setIsSuccess(false);
      setLoading(false);
      setQuery(false);
    } finally {
      setQuery(false);
      const _totalReceived = await contractRoyalty["released(address,uint256)"](
        WETHAddress,
        BigNumber.from(2)
      );
      setRecDetail((prevstate) => ({
        ...prevstate,
        royal: formatNumb(_totalReceived),
        accum: 0,
        accumNative: 0,
      }));
    }
  };
  const handleDistributeNative = async () => {
    const signer = await provider.getSigner();
    const contracts = new ethers.Contract(RoyaltyAddress, ROYALTY, signer);
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas(gasUrl);
      const _distribute = await contracts.distributeDonation({
        ...gas,
      });
      setLoading(false);
      setTriger(true);
      const tx = await _distribute.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (error) {
      console.error(error);
      setToast("Distribute Donation Fail");
      setIsSuccess(false);
      setLoading(false);
      setQuery(false);
    } finally {
      setQuery(false);
      const _totalReceivedNative = await contractRoyalty["released(uint256)"](
        BigNumber.from(2)
      );
      setRecDetail((prevstate) => ({
        ...prevstate,
        royalNative: formatNumb(_totalReceivedNative),
        accum: 0,
        accumNative: 0,
      }));
    }
  };

  return (
    <>
      {modal && (
        <Layer animate modal position="right" full="vertical">
          <StyledBox
            align="center"
            direction="column"
            justify="start"
            width="xlarge"
            height="100%"
            fill="vertical"
            elevation="xlarge"
            responsive
            overflow="auto"
            animation={{ type: "slideLeft", size: "large" }}
          >
            <StyledBox
              direction="column"
              justify="between"
              align="center"
              flex={false}
              border={{ color: "light-1", size: "small", side: "bottom" }}
              gridArea="header"
              pad={{ bottom: "medium" }}
              width="100%"
              height="xsmall"
            >
              <StyledBox
                align="center"
                justify="center"
                basis="xxsmall"
                alignSelf="start"
                margin="small"
                pad={{ top: "xsmall" }}
              >
                <Button
                  primary
                  icon={<Close />}
                  secondary={false}
                  type="button"
                  hoverIndicator
                  size="small"
                  color="active-text"
                  reverse={false}
                  onClick={() => openDao(undefined)}
                  margin={{ top: "medium" }}
                />
              </StyledBox>
              <StyledBox
                align="center"
                justify="end"
                direction="column"
                fill="vertical"
              >
                <Heading
                  textAlign="center"
                  size="medium"
                  level="2"
                  margin={{ bottom: "none", vertical: "none", top: "none" }}
                >
                  DAO Section
                </Heading>
                <Paragraph
                  margin={{ vertical: "none", top: "xsmall" }}
                  size="medium"
                  textAlign="center"
                >
                  Smart Contract Address :
                </Paragraph>
                <Anchor
                  label={DAOAddress}
                  href={`${blockUrl}/${DAOAddress}`}
                  target="_blank"
                />
              </StyledBox>
            </StyledBox>
            <StyledBox
              align="stretch"
              justify="start"
              direction="column"
              gap="none"
              pad="medium"
              responsive
              fill="horizontal"
            >
              <StyledBox
                align="center"
                justify="center"
                direction="column"
                elevation="small"
                height="xxsmall"
                round="small"
                pad="xsmall"
                flex={false}
              >
                <Heading
                  size="medium"
                  level="3"
                  textAlign="center"
                  margin={{ bottom: "small" }}
                >
                  DAO Stage : {loadStage(stage)}
                </Heading>
              </StyledBox>
              <Tabs
                justify="start"
                activeIndex={index}
                onActive={onActive}
                flex={false}
              >
                <Tab
                  title="Detail"
                  icon={<Article />}
                  plain={false}
                  reverse={false}
                  disabled={false}
                >
                  <StyledBox
                    direction="row-responsive"
                    flex={false}
                    pad="small"
                    justify="start"
                    alignSelf="start"
                    margin="small"
                  >
                    <StyledBox
                      align="center"
                      justify="center"
                      gap="small"
                      flex={false}
                    >
                      <Heading size="medium" level="3" textAlign="center">
                        Current Donation Receiver Detail
                      </Heading>

                      <StyledBox basis="small" width="medium">
                        <Image
                          fit="contain"
                          src={placeholder}
                          full="horizontal"
                        />
                      </StyledBox>
                    </StyledBox>
                    <StyledBox
                      flex={false}
                      pad="small"
                      direction="column"
                      gap="small"
                    >
                      <Heading level={2} textAlign="center">
                        {recDetail.title}
                      </Heading>
                      <Paragraph size="large">URL :</Paragraph>
                      <Anchor
                        label={recDetail.url}
                        href={recDetail.url}
                        target="_blank"
                      />
                      <Paragraph size="large">
                        Donation Receiver Address :
                      </Paragraph>
                      <Anchor
                        label={recDetail.address}
                        href={`${blockUrl}/${recDetail.address}`}
                        target="_blank"
                      />
                      <Paragraph size="large">Description :</Paragraph>
                      <Paragraph>{recDetail.desc}</Paragraph>
                      <Paragraph size="large">Total WETH Released :</Paragraph>
                      <Paragraph>{recDetail.royal} WETH</Paragraph>
                      <Paragraph size="large">Total Matic Released :</Paragraph>
                      <Paragraph>{recDetail.royalNative} Matic</Paragraph>
                    </StyledBox>
                  </StyledBox>
                  {!postpone && (
                    <StyledBox
                      direction="row"
                      size="medium"
                      align="center"
                      justify="evenly"
                      fill="horizontal"
                      pad={{ horizontal: "small" }}
                    >
                      <StyledBox direction="column" gap="small">
                        <Heading level="3" textAlign="center">
                          WETH Donation Accumulated
                        </Heading>
                        <Paragraph textAlign="center">
                          {recDetail.accum} WETH
                        </Paragraph>
                        <Button
                          label={
                            queryNetwork
                              ? "waiting block confirmation"
                              : "Distribute"
                          }
                          color="active-text"
                          onClick={() => handleDistribute()}
                          disabled={recDetail.accum == 0 || queryNetwork}
                          size="small"
                        />
                      </StyledBox>
                      <StyledBox direction="column" gap="small">
                        <Heading level="3" textAlign="center">
                          Matic Donation Accumulated
                        </Heading>
                        <Paragraph textAlign="center">
                          {recDetail.accumNative} Matic
                        </Paragraph>
                        <Button
                          label={
                            queryNetwork
                              ? "waiting block confirmation"
                              : "Distribute"
                          }
                          color="active-text"
                          onClick={() => handleDistributeNative()}
                          disabled={recDetail.accumNative == 0 || queryNetwork}
                          size="small"
                        />
                      </StyledBox>
                    </StyledBox>
                  )}
                  <StyledBox
                    direction="column"
                    margin="small"
                    full="horizontal"
                    pad="medium"
                    gap="medium"
                  >
                    <StyledBox
                      align="center"
                      justify="center"
                      direction="column"
                      elevation="large"
                      pad="small"
                      width="full"
                      alignSelf="center"
                    >
                      <Heading
                        size="medium"
                        level="2"
                        textAlign="center"
                        margin={{ bottom: "small" }}
                      >
                        Countdown Until Next Vote
                      </Heading>
                      <CountdownTimer targetDate={time} />
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      direction="column"
                      elevation="large"
                      pad="small"
                      width="full"
                      fill="vertical"
                      alignSelf="center"
                    >
                      <Heading
                        size="medium"
                        level="2"
                        textAlign="center"
                        margin={{ bottom: "small" }}
                      >
                        Total Pending Proposal
                      </Heading>
                      <Paragraph margin={{ top: "none" }} size="xlarge">
                        {pendingCount}
                      </Paragraph>
                    </StyledBox>
                  </StyledBox>
                  <StyledBox
                    align="center"
                    justify="around"
                    direction="row"
                    pad="medium"
                    gap="medium"
                    flex={false}
                    fill="horizontal"
                  >
                    <StyledBox
                      align="center"
                      justify="center"
                      direction="column"
                      elevation="large"
                      pad="small"
                      width="50%"
                    >
                      <Heading
                        size="medium"
                        level="3"
                        textAlign="center"
                        margin={{ bottom: "small" }}
                      >
                        Voting Owned For Next Proposal
                      </Heading>
                      <Paragraph margin={{ top: "none" }} size="xlarge">
                        {balance}
                      </Paragraph>
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      direction="column"
                      elevation="large"
                      pad="small"
                      width="50%"
                    >
                      <Heading
                        size="medium"
                        level="3"
                        textAlign="center"
                        margin={{ bottom: "small" }}
                      >
                        Total Voting Unit For Next Proposal
                      </Heading>
                      <Paragraph margin={{ top: "none" }} size="xlarge">
                        {nextVoting}
                      </Paragraph>
                    </StyledBox>
                  </StyledBox>
                </Tab>
                {stage > 1 ? undefined : (
                  <Tab title="Submit Proposal" icon={<Edit />}>
                    <StyledBox
                      align="center"
                      justify="center"
                      responsive
                      margin={{ vertical: "medium" }}
                    >
                      <Paragraph textAlign="center">
                        Your Current Voting Unit is calculated by the amount of
                        your total NFT Owned when the DAO stage is starting.
                      </Paragraph>
                      {stage === 0 ? (
                        <Button
                          label="Start DAO Stage"
                          color="active-text"
                          margin={{ vertical: "medium" }}
                          tip="When DAO stage = Voting End and Countdown to Next Vote Reach Zero
                         This Button will push the stage into the next stage."
                          onClick={() => startProps()}
                          disabled={
                            connect ||
                            stage !== 0 ||
                            Date.now() < timeVote(time, 0) ||
                            queryNetwork
                          }
                        />
                      ) : (
                        <>
                          <Paragraph
                            textAlign="center"
                            margin={{ vertical: "small" }}
                          >
                            You must own at least{" "}
                            {votingUnit < 5000 ? "40" : "20"}% of total voting
                            unit to submit new proposal. you can ask other
                            holder to delegate their voting unit to you or
                            delegate your voting unit to other holder or you can
                            submit pending proposal below 14 days after DAO
                            Stage Starting.
                          </Paragraph>
                          <hr style={{ width: "100%" }} />
                        </>
                      )}
                    </StyledBox>
                    {isDelegating ? (
                      <StyledBox
                        align="center"
                        alignSelf="center"
                        justify="center"
                        direction="column"
                        pad={{ horizontal: "small", vertical: "large" }}
                        gap="medium"
                        flex={false}
                        fill="horizontal"
                        margin={{ vertical: "medium" }}
                        elevation="medium"
                        round="small"
                      >
                        <Paragraph>
                          You already delegate your voting unit to :
                        </Paragraph>
                        <Text truncate>
                          <Anchor
                            label={delegateTo}
                            href={`${blockUrl}/${delegateTo}`}
                            target="_blank"
                          />
                        </Text>
                        <br />
                        <Paragraph>
                          Please wait until next voting period
                        </Paragraph>
                      </StyledBox>
                    ) : (
                      <>
                        <StyledBox
                          align="center"
                          justify="around"
                          direction="row"
                          pad="medium"
                          gap="medium"
                          flex={false}
                          fill="horizontal"
                        >
                          <StyledBox
                            align="center"
                            justify="center"
                            direction="column"
                            elevation="large"
                            pad="small"
                            width="50%"
                          >
                            <Heading
                              size="medium"
                              level="3"
                              textAlign="center"
                              margin={{ bottom: "small" }}
                            >
                              Current Voting Unit :
                            </Heading>
                            <Paragraph margin={{ top: "none" }} size="xlarge">
                              {currentUnit}
                            </Paragraph>
                          </StyledBox>
                          <StyledBox
                            align="center"
                            justify="center"
                            direction="column"
                            elevation="large"
                            pad="small"
                            width="50%"
                          >
                            <Heading
                              size="medium"
                              level="3"
                              textAlign="center"
                              margin={{ bottom: "small" }}
                            >
                              Total Delegated Voting Unit :
                            </Heading>
                            <Paragraph margin={{ top: "none" }} size="xlarge">
                              {delegatedUnit}
                            </Paragraph>
                          </StyledBox>
                        </StyledBox>
                        <StyledBox
                          fill="horizontal"
                          direction="column"
                          pad="medium"
                          gap="small"
                          elevation="medium"
                          round="small"
                          margin={{ bottom: "medium" }}
                          justify="center"
                          align="center"
                        >
                          <Heading
                            size="medium"
                            level="3"
                            textAlign="center"
                            margin={{ bottom: "small" }}
                          >
                            Your Total Voting Unit
                          </Heading>
                          <Paragraph
                            margin={{ top: "none" }}
                            size="xlarge"
                            textAlign="center"
                          >
                            {votingWeight}
                          </Paragraph>
                        </StyledBox>
                        <StyledBox
                          width="full"
                          round="small"
                          elevation="large"
                          flex={false}
                          margin={{ bottom: "2rem" }}
                        >
                          <Form
                            value={value}
                            validate="change"
                            onChange={(nextValue, { touched }) => {
                              setValue(nextValue);
                              validate(nextValue);
                            }}
                          >
                            <FormField
                              label="Enter Wallet Address"
                              name="address"
                              validate={[
                                (address) => {
                                  if (!_isAddress(address))
                                    return "Please enter valid Wallet Address";
                                  return undefined;
                                },
                              ]}
                            />
                            <StyledBox
                              justify="between"
                              margin={{ top: "small" }}
                              pad="small"
                              round="small"
                            >
                              <Button
                                label="Delegate Your Voting Unit"
                                disabled={!valid || queryNetwork}
                                primary
                                onClick={() => setLayerDelegate(true)}
                              />
                            </StyledBox>
                          </Form>
                        </StyledBox>
                        <SubmitProposal
                          provider={provider}
                          metReq={metReq}
                          stage={stage}
                          setLoading={setLoading}
                          setHash={setHash}
                          setSubmiting={setSubmiting}
                          fetchStage={fetchStage}
                          setTriger={setTriger}
                          setTransHash={setTransHash}
                          relayer={relayer}
                        />
                        <StyledBox
                          align="center"
                          justify="between"
                          direction="row"
                          responsive
                          round="small"
                          elevation="small"
                          pad="medium"
                        >
                          <StyledBox
                            align="center"
                            alignSelf="center"
                            width="40%"
                            pad="small"
                            gap="medium"
                            direction="column"
                            fill="vertical"
                            justify="between"
                          >
                            <Heading level="2" textAlign="center">
                              Pending Proposal
                            </Heading>
                            <Paragraph textAlign="center" size="small">
                              When Quorum not reached, The Status of the
                              Proposal will be Pending.
                            </Paragraph>
                          </StyledBox>
                          <StyledBox
                            align="center"
                            justify="end"
                            responsive
                            direction="column"
                            fill
                          >
                            <Carousel fill controls="arrows">
                              {oldProps === 0 ? (
                                <StyledBox
                                  align="start"
                                  justify="center"
                                  pad="medium"
                                  alignSelf="center"
                                  responsive
                                >
                                  <NameValueList
                                    layout="row-responsive"
                                    align="center"
                                  >
                                    <NameValuePair name="Proposal ID :">
                                      <Text color="text-strong">
                                        no pending proposal
                                      </Text>
                                    </NameValuePair>
                                    <NameValuePair name="URL :">
                                      <Text color="text-strong">
                                        no pending proposal
                                      </Text>
                                    </NameValuePair>
                                    <NameValuePair name="Donation Receiver :">
                                      <Text color="text-strong">
                                        no pending proposal
                                      </Text>
                                    </NameValuePair>
                                    <NameValuePair name="Description :">
                                      <Text color="text-strong">
                                        no pending proposal
                                      </Text>
                                    </NameValuePair>
                                  </NameValueList>
                                </StyledBox>
                              ) : (
                                pendingProposal.map((pend, i) => (
                                  <StyledBox
                                    align="start"
                                    justify="center"
                                    pad="medium"
                                    alignSelf="center"
                                    round="small"
                                    background={{
                                      color: `${
                                        pend.isOld ? "dark-1" : undefined
                                      }`,
                                    }}
                                    key={i}
                                  >
                                    <NameValueList
                                      layout="row-responsive"
                                      align="center"
                                    >
                                      <NameValuePair name="Title :">
                                        <Text color="text-strong">
                                          {pend.title}
                                        </Text>
                                      </NameValuePair>
                                      <NameValuePair name="URL :">
                                        <Text color="text-strong">
                                          <Anchor
                                            label={pend.url}
                                            href={pend.url}
                                            target="_blank"
                                          />
                                        </Text>
                                      </NameValuePair>
                                      <NameValuePair name="Donation Receiver :">
                                        <Text>
                                          <Anchor
                                            label={pend.recevierAddress}
                                            href={`${blockUrl}/${pend.recevierAddress}`}
                                            target="_blank"
                                          />
                                        </Text>
                                      </NameValuePair>
                                      <NameValuePair name="Description :">
                                        <Text color="text-strong">
                                          {pend.desc}
                                        </Text>
                                      </NameValuePair>
                                    </NameValueList>
                                    <StyledBox
                                      align="center"
                                      justify="center"
                                      fill
                                      margin={{ top: "small" }}
                                    >
                                      <Button
                                        label={
                                          pend.isOld
                                            ? "Proposal are Executed"
                                            : "Submit"
                                        }
                                        color="active-text"
                                        type="button"
                                        disabled={
                                          connect || pend.isOld || queryNetwork
                                        }
                                        size="small"
                                        onClick={() =>
                                          submitPending(pend.pendingIndex)
                                        }
                                      />
                                    </StyledBox>
                                  </StyledBox>
                                ))
                              )}
                            </Carousel>
                          </StyledBox>
                        </StyledBox>
                      </>
                    )}
                  </Tab>
                )}
                {stage === 1 ? undefined : (
                  <>
                    <Tab title="Vote" icon={<Cluster />}>
                      <StyledBox
                        align="center"
                        justify="center"
                        elevation="none"
                        fill="horizontal"
                        round="small"
                        margin="small"
                      >
                        <Heading
                          size="medium"
                          level="2"
                          textAlign="center"
                          margin={{ bottom: "small", top: "xsmall" }}
                        >
                          {proposals.name}
                        </Heading>
                        <Heading
                          size="medium"
                          level="3"
                          textAlign="center"
                          margin={{ bottom: "small", top: "xsmall" }}
                        >
                          Proposal Status :{" "}
                          {!proposals.isEnding ? "Running" : results(result)}
                        </Heading>
                        <StyledBox
                          align="center"
                          justify="center"
                          elevation="none"
                          round="small"
                          margin="small"
                          direction="row-responsive"
                          gap="small"
                          fill="horizontal"
                        >
                          <Heading
                            size="medium"
                            level="3"
                            textAlign="start"
                            margin="xsmall"
                          >
                            Your Voting Weight :{" "}
                            {queryNetwork
                              ? "...fetch data"
                              : isDelegating
                              ? 0
                              : votingWeight}
                          </Heading>
                          <Heading
                            size="medium"
                            level="3"
                            textAlign="start"
                            margin="xsmall"
                          >
                            Total Voting Unit :{" "}
                            {queryNetwork ? "...fetch data" : votingUnit}
                          </Heading>
                        </StyledBox>
                      </StyledBox>

                      <StyledBox
                        align="center"
                        justify="center"
                        direction="row-responsive"
                        responsive
                        margin={{ vertical: "large" }}
                        round="small"
                        pad="medium"
                      >
                        <StyledBox
                          basis="small"
                          width="medium"
                          gap="medium"
                          margin="medium"
                        >
                          <Heading size="medium" level="2" textAlign="center">
                            {proposals.title}
                          </Heading>
                          <Image
                            fit="contain"
                            src={proposals.image}
                            full="horizontal"
                          />
                        </StyledBox>
                        <NameValueList
                          align="start"
                          valueProps={{ width: "large", align: "start" }}
                          pairProps={{ direction: "column" }}
                        >
                          <NameValuePair name="URL :">
                            <Text>
                              <Anchor
                                label={proposals.url}
                                href={proposals.url}
                                target="_blank"
                              />
                            </Text>
                          </NameValuePair>
                          <NameValuePair name="Donation Receiver Address :">
                            <Text truncate>
                              <Anchor
                                label={proposals.address}
                                href={`${blockUrl}/${proposals.address}`}
                                target="_blank"
                              />
                            </Text>
                          </NameValuePair>
                          <NameValuePair name="Description :">
                            <Text color="text-strong">{proposals.desc}</Text>
                          </NameValuePair>
                        </NameValueList>
                      </StyledBox>
                      {!hasVoted && !isDelegating && (
                        <StyledBox
                          align="center"
                          justify="center"
                          margin={{ vertical: "medium" }}
                          pad="medium"
                          direction="column"
                          gap="xsmall"
                          responsive
                          fill="horizontal"
                          elevation="large"
                          round="small"
                        >
                          <Grid
                            columns={{ size: "full", count: "fit" }}
                            rows={["1/4", "1/4", "1/4", "1/4"]}
                            gap="xsmall"
                            align="start"
                            alignContent="center"
                            margin={{ top: "xsmall" }}
                          >
                            <StyledBox
                              align="center"
                              justify="center"
                              round="small"
                              pad="xsmall"
                              border={{ color: "active-text", side: "bottom" }}
                              flex="grow"
                              responsive
                              margin={{ top: "small" }}
                            >
                              <Button
                                label="Abstain"
                                plain
                                icon={<StatusGood />}
                                gap="large"
                                hoverIndicator
                                size="large"
                                type="button"
                                active={false}
                                onClick={() => setLayerAbs(true)}
                              />
                            </StyledBox>
                            <StyledBox
                              align="center"
                              justify="center"
                              round="small"
                              pad="xsmall"
                              border={{ color: "active-text", side: "bottom" }}
                              flex="grow"
                              responsive
                            >
                              <Button
                                label="Accept"
                                plain
                                icon={<StatusGood />}
                                gap="large"
                                hoverIndicator
                                size="large"
                                type="button"
                                active={false}
                                onClick={() => setLayerYay(true)}
                              />
                            </StyledBox>
                            <StyledBox
                              align="center"
                              justify="center"
                              round="small"
                              pad="xsmall"
                              border={{ color: "active-text", side: "bottom" }}
                              flex="grow"
                              responsive
                            >
                              <Button
                                label="Reject"
                                plain
                                icon={<StatusGood />}
                                gap="large"
                                hoverIndicator
                                size="large"
                                type="button"
                                active={false}
                                onClick={() => setLayerNay(true)}
                              />
                            </StyledBox>
                            <StyledBox
                              align="center"
                              justify="center"
                              round="small"
                              pad="xsmall"
                              border={{ color: "active-text", side: "bottom" }}
                              flex="grow"
                              responsive
                            >
                              <Button
                                label="Postpone Project"
                                plain
                                icon={<StatusGood />}
                                gap="medium"
                                hoverIndicator
                                size="large"
                                type="button"
                                active={false}
                                margin={{ bottom: "xsmall" }}
                                onClick={() => setLayerKill(true)}
                              />
                            </StyledBox>
                          </Grid>
                        </StyledBox>
                      )}
                    </Tab>
                  </>
                )}
                {stage === 1 ? undefined : (
                  <>
                    <Tab title="Voting Result" icon={<BarChart />}>
                      <StyledBox
                        align="center"
                        justify="between"
                        direction="column"
                        pad="medium"
                        elevation="medium"
                        round="small"
                        gap="medium"
                      >
                        <Heading level="2" textAlign="center">
                          {proposals.name}
                        </Heading>
                        {proposals.isEnding ? (
                          <Heading level="3" textAlign="center">
                            Voting Result : {results(result)}
                          </Heading>
                        ) : Date.now() > countingDay ? (
                          <Button
                            label="Count Vote"
                            color="active-text"
                            onClick={() => count()}
                            disabled={connect || stage !== 2 || queryNetwork}
                          />
                        ) : (
                          <>
                            <Heading
                              level="3"
                              textAlign="center"
                              margin="small"
                            >
                              Voting End Until
                            </Heading>
                            <CountdownTimer targetDate={countingDay} />
                          </>
                        )}
                      </StyledBox>

                      <StyledBox
                        align="center"
                        justify="center"
                        pad="small"
                        gap="small"
                        margin={{ vertical: "medium" }}
                        elevation="medium"
                      >
                        <Heading level="2" textAlign="center">
                          Voting Pool
                        </Heading>
                        <DataChart
                          axis={{
                            x: { granularity: "fine", property: "vote" },
                            y: { granularity: "fine" },
                          }}
                          data={[
                            { vote: "Abstain", amount: res.abstain },
                            { vote: "Accepted", amount: res.yay },
                            { vote: "Rejected", amount: res.nay },
                            { vote: "Postponed", amount: res.postpone },
                          ]}
                          series={["vote", "amount"]}
                          bounds={{
                            y: [0, votingUnit > 1 ? votingUnit : 10000],
                            x: [0, 3],
                          }}
                          guide={{
                            x: { granularity: "medium" },
                            y: { granularity: "medium" },
                          }}
                          size={{ width: "medium", height: "medium" }}
                          detail={false}
                          pad="xsmall"
                          chart={[
                            {
                              type: "bar",
                              property: "amount",
                              color: "active-text",
                              thickness: "medium",
                              dash: false,
                              round: false,
                            },
                          ]}
                        />
                        <StyledBox
                          align="center"
                          justify="center"
                          margin={{ vertical: "medium" }}
                        >
                          <Paragraph textAlign="center">
                            Qourum reached when total vote of (Accpted +
                            Rejected + Postpone) are greater than 40% Total
                            Voting Unit
                          </Paragraph>
                        </StyledBox>
                      </StyledBox>
                      <StyledBox
                        align="center"
                        justify="start"
                        pad="large"
                        margin={{ top: "medium" }}
                        direction="column"
                        elevation="medium"
                        round="small"
                        gap="medium"
                      >
                        <Heading level="3" textAlign="center" size="medium">
                          Execute Proposal
                        </Heading>
                        <Button
                          label="Excecute"
                          color="active-text"
                          onClick={() => executeProps()}
                          disabled={!connect || stage !== 3 || queryNetwork}
                        />
                      </StyledBox>
                    </Tab>
                  </>
                )}
              </Tabs>
            </StyledBox>
            {isLoading && (
              <Layer
                full
                modal
                background={{ color: "light-3", opacity: "strong" }}
                position="center"
                animate={false}
              >
                <StyledBox
                  fill
                  align="center"
                  justify="center"
                  direction="column"
                >
                  <Spinner size="xlarge" color="active-text" />
                  {hashing && (
                    <StyledBox
                      fill="horizontal"
                      animation={{ type: "pulse", size: "medium" }}
                    >
                      <Heading
                        responsive
                        textAlign="center"
                        size="2rem"
                        align="center"
                      >
                        Hashing Proposal DNA ..Dont Close Your Browser
                      </Heading>
                    </StyledBox>
                  )}
                  {submiting && (
                    <StyledBox
                      fill="horizontal"
                      animation={{ type: "pulse", size: "medium" }}
                    >
                      <Heading
                        responsive
                        textAlign="center"
                        size="2rem"
                        align="center"
                      >
                        Submiting Proposal to Smart Contract
                      </Heading>
                    </StyledBox>
                  )}
                </StyledBox>
              </Layer>
            )}
          </StyledBox>
          {layerAbs && (
            <Layer animate modal responsive margin="none" plain>
              <StyledBox
                align="center"
                justify="between"
                direction="column"
                width="large"
                round="small"
                elevation="large"
                background={{ color: "background-front" }}
              >
                <StyledBox
                  align="start"
                  justify="between"
                  fill="horizontal"
                  pad={{ vertical: "small", horizontal: "small" }}
                  direction="row"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    pad={{ horizontal: "medium" }}
                  >
                    <Button
                      icon={<Close />}
                      margin={{ left: "xsmall" }}
                      primary
                      color="active-text"
                      onClick={() => setLayerAbs(undefined)}
                    />
                  </StyledBox>
                  <StyledBox align="center" justify="center" direction="column">
                    <StyledBox
                      align="center"
                      justify="center"
                      margin={{ bottom: "xsmall" }}
                    >
                      <Text weight="bold" margin={{ top: "xsmall" }}>
                        You vote : Abstain
                      </Text>
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      pad={{ horizontal: "medium" }}
                    >
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Are you sure about the choice? Once you submit you can't
                        vote again until next proposal.
                      </Text>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="between"
                  direction="row"
                  width="100%"
                  gap="none"
                  pad={{ bottom: "small", vertical: "small" }}
                  background={{ color: "dark-1" }}
                  round="xsmall"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Text textAlign="center" weight="bold">
                      Your Voting Weight : {votingWeight}
                    </Text>
                  </StyledBox>
                  {stage !== 2 && (
                    <Text
                      textAlign="center"
                      color="status-critical"
                      size="medium"
                      margin="none"
                    >
                      You can cast vote if stage is at Voting Period
                    </Text>
                  )}
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Button
                      label="Vote"
                      primary
                      type="button"
                      plain={false}
                      disabled={connect || stage !== 2 || queryNetwork}
                      color="active-text"
                      margin={{ bottom: "xsmall" }}
                      onClick={() => {
                        setLayerAbs(undefined);
                        enterVote(0);
                      }}
                    />
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </Layer>
          )}
          {layerYay && (
            <Layer animate modal responsive margin="none" plain>
              <StyledBox
                align="center"
                justify="between"
                direction="column"
                width="large"
                round="small"
                elevation="large"
                background={{ color: "background-front" }}
              >
                <StyledBox
                  align="start"
                  justify="between"
                  fill="horizontal"
                  pad={{ vertical: "small", horizontal: "small" }}
                  direction="row"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    pad={{ horizontal: "medium" }}
                  >
                    <Button
                      icon={<Close />}
                      margin={{ left: "xsmall" }}
                      primary
                      color="active-text"
                      onClick={() => setLayerYay(undefined)}
                    />
                  </StyledBox>
                  <StyledBox align="center" justify="center" direction="column">
                    <StyledBox
                      align="center"
                      justify="center"
                      margin={{ bottom: "xsmall" }}
                    >
                      <Text weight="bold" margin={{ top: "xsmall" }}>
                        You vote : Accept
                      </Text>
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      pad={{ horizontal: "medium" }}
                    >
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Are you sure about the choice? Once you submit you can't
                        vote again until next proposal.
                      </Text>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="between"
                  direction="row"
                  width="100%"
                  gap="none"
                  pad={{ bottom: "small", vertical: "small" }}
                  background={{ color: "dark-1" }}
                  round="xsmall"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Text textAlign="center" weight="bold">
                      Your Voting Weight : {votingWeight} Voting Unit
                    </Text>
                  </StyledBox>
                  {stage !== 2 && (
                    <Text
                      textAlign="center"
                      color="status-critical"
                      size="medium"
                      margin="none"
                    >
                      You can cast vote if stage is at Voting Period
                    </Text>
                  )}
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Button
                      label="Vote"
                      primary
                      type="button"
                      plain={false}
                      disabled={connect || stage !== 2 || queryNetwork}
                      color="active-text"
                      margin={{ bottom: "xsmall" }}
                      onClick={() => {
                        setLayerYay(undefined);
                        enterVote(1);
                      }}
                    />
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </Layer>
          )}
          {layerNay && (
            <Layer animate modal responsive margin="none" plain>
              <StyledBox
                align="center"
                justify="between"
                direction="column"
                width="large"
                round="small"
                elevation="large"
                background={{ color: "background-front" }}
              >
                <StyledBox
                  align="start"
                  justify="between"
                  fill="horizontal"
                  pad={{ vertical: "small", horizontal: "small" }}
                  direction="row"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    pad={{ horizontal: "medium" }}
                  >
                    <Button
                      icon={<Close />}
                      margin={{ left: "xsmall" }}
                      primary
                      color="active-text"
                      onClick={() => setLayerNay(undefined)}
                    />
                  </StyledBox>
                  <StyledBox align="center" justify="center" direction="column">
                    <StyledBox
                      align="center"
                      justify="center"
                      margin={{ bottom: "xsmall" }}
                    >
                      <Text weight="bold" margin={{ top: "xsmall" }}>
                        You vote : Reject
                      </Text>
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      pad={{ horizontal: "medium" }}
                    >
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Are you sure about the choice? Once you submit you can't
                        vote again until next proposal.
                      </Text>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="between"
                  direction="row"
                  width="100%"
                  gap="none"
                  pad={{ bottom: "small", vertical: "small" }}
                  background={{ color: "dark-1" }}
                  round="xsmall"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Text textAlign="center" weight="bold">
                      Your Voting Weight : {votingWeight} Voting Unit
                    </Text>
                  </StyledBox>
                  {stage !== 2 && (
                    <Text
                      textAlign="center"
                      color="status-critical"
                      size="medium"
                      margin="none"
                    >
                      You can cast vote if stage is at Voting Period
                    </Text>
                  )}
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Button
                      label="Vote"
                      primary
                      type="button"
                      plain={false}
                      color="active-text"
                      margin={{ bottom: "xsmall" }}
                      onClick={() => {
                        setLayerNay(undefined);
                        enterVote(2);
                      }}
                      disabled={connect || stage !== 2 || queryNetwork}
                    />
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </Layer>
          )}
          {layerKill && (
            <Layer animate modal responsive margin="none" plain>
              <StyledBox
                align="center"
                justify="between"
                direction="column"
                width="large"
                round="small"
                elevation="large"
                background={{ color: "background-front" }}
              >
                <StyledBox
                  align="start"
                  justify="between"
                  fill="horizontal"
                  pad={{ vertical: "small", horizontal: "small" }}
                  direction="row"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    pad={{ horizontal: "medium" }}
                  >
                    <Button
                      icon={<Close />}
                      margin={{ left: "xsmall" }}
                      primary
                      color="active-text"
                      onClick={() => setLayerKill(undefined)}
                    />
                  </StyledBox>
                  <StyledBox align="center" justify="center" direction="column">
                    <StyledBox
                      align="center"
                      justify="center"
                      margin={{ bottom: "xsmall" }}
                    >
                      <Text weight="bold" margin={{ top: "xsmall" }}>
                        You vote : Postpone
                      </Text>
                    </StyledBox>
                    <StyledBox
                      align="center"
                      justify="center"
                      pad={{ horizontal: "medium" }}
                    >
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Are you sure about the choice? Once you submit you can't
                        vote again until next proposal.
                      </Text>
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Postpone Project mean royalty for donation will be
                        distributed to Token Holder
                      </Text>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="between"
                  direction="row"
                  width="100%"
                  gap="none"
                  pad={{ bottom: "small", vertical: "small" }}
                  background={{ color: "dark-1" }}
                  round="xsmall"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Text textAlign="center" weight="bold">
                      Your Voting Weight : {votingWeight} Voting Unit
                    </Text>
                  </StyledBox>
                  {stage !== 2 && (
                    <Text
                      textAlign="center"
                      color="status-critical"
                      size="medium"
                      margin="none"
                    >
                      Require total supply of NFT reach maximum supply and Stage
                      is at Voting Period
                    </Text>
                  )}
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Button
                      label="Vote"
                      primary
                      type="button"
                      plain={false}
                      disabled={
                        connect || stage !== 2 || !isMetTotal || queryNetwork
                      }
                      color="active-text"
                      margin={{ bottom: "xsmall" }}
                      onClick={() => {
                        setLayerKill(undefined);
                        killVote();
                      }}
                    />
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </Layer>
          )}
          {layerDelegate && (
            <Layer animate modal responsive margin="none" plain>
              <StyledBox
                align="center"
                justify="between"
                direction="column"
                width="large"
                round="small"
                elevation="large"
                background={{ color: "background-front" }}
              >
                <StyledBox
                  align="start"
                  justify="between"
                  fill="horizontal"
                  pad={{ vertical: "small", horizontal: "small" }}
                  direction="row"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    pad={{ horizontal: "medium" }}
                    border
                  >
                    <Button
                      icon={<Close />}
                      margin={{ left: "xsmall" }}
                      primary
                      color="active-text"
                      onClick={() => setLayerDelegate(undefined)}
                    />
                  </StyledBox>
                  <StyledBox align="center" justify="center" direction="column">
                    <StyledBox
                      align="center"
                      justify="center"
                      pad={{ horizontal: "medium" }}
                    >
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Are you sure about the choice? Once you delegate your
                        vote you can't vote again until the next proposal.
                      </Text>
                      <Text weight="bold" margin={{ vertical: "medium" }}>
                        Delegating your vote mean you give your voting unit to
                        other holder. After voting period end, You will have
                        your voting unit back automatically. <br /> <br />
                        {balance + delegatedUnit} Voting unit delegated{" "}
                      </Text>
                    </StyledBox>
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="between"
                  direction="row"
                  width="100%"
                  gap="none"
                  pad={{ bottom: "small", vertical: "small" }}
                  background={{ color: "dark-1" }}
                  round="xsmall"
                >
                  <StyledBox
                    align="center"
                    justify="center"
                    margin={{ horizontal: "large" }}
                  >
                    <Button
                      label="Delegate"
                      primary
                      type="button"
                      plain={false}
                      disabled={connect || stage !== 1 || queryNetwork}
                      color="active-text"
                      margin={{ bottom: "xsmall" }}
                      onClick={() => {
                        setLayerDelegate(undefined);
                        delegateVote(value.address);
                      }}
                    />
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </Layer>
          )}
        </Layer>
      )}
      <Modals toast={toast} setToast={setToast} success={isSuccess} />;
    </>
  );
};

export default TheDao;
