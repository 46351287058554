import { useState } from "react";
import "../index.css";

const Affiliator = () => {
  const [passed, setPassed] = useState(false);

  const num = [...Array(100).keys()];

  return (
    <div className="flex flex-col text-white font-sans w-screen h-[200vh] bg-gradient-to-bl from-indigo-900 via-slate-900 to-black p-5">
      <h1 className="text-2xl lg:text-4xl font-bold">Affiliate Page</h1>
      {passed ? (
        <div className="m-auto lg:w-1/4 shadow-lg drop-shadow-2xl shadow-teal-700/80 border-[1px] border-blue-600/30 p-5 flex flex-col rounded-xl gap-5">
          <h1 className="text-xl lg:text-2xl font-semibold border-b-2 border-white">
            Authenticate
          </h1>
          <div className="w-full flex flex-col gap-2">
            <label className="text-lg">Username</label>
            <input className="bg-slate-500 active:bg-slate-400 rounded-lg h-8" />
            <label className="text-lg">Password</label>
            <input className="bg-slate-500 active:bg-slate-400 rounded-lg h-8" />
            <div className="mt-2">
              <button className="px-3 active:scale-90 lg:w-1/4 mx-auto py-2 bg-gradient-to-r from-blue-700 via-blue-500 to-blue-400 font-semibold rounded-xl">
                Login
              </button>
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="border-b-2 border-white border-dashed w-1/3 h-1" />
            <span className="text-xl font-semibold">Or</span>
            <div className="border-b-2 border-white w-1/3 border-dashed h-1" />
          </div>
          <button className="px-3 active:scale-90 lg:w-1/3 mx-auto py-2 bg-gradient-to-r from-red-700 via-red-500 to-orange-500 font-semibold rounded-xl">
            Connect Wallet
          </button>
        </div>
      ) : (
        <div className="m-auto relative h-96 w-full border-y-4 p-5">
          <div className="w-full h-full flex gap-4 overflow-x-scroll overscroll-x-contain whitespace-nowrap">
            {num.map((number, i) => (
              <div
                key={i}
                className="inline-block border-2 w-20 h-20 items-center"
              >
                <h1>{number}</h1>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Affiliator;
