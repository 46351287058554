import { useState, useContext, useEffect, useCallback } from "react";
import {
  Layer,
  Box,
  Grid,
  Button,
  Heading,
  Paragraph,
  Card,
  Image,
  Stack,
  Meter,
  ResponsiveContext,
  Anchor,
  Spinner,
} from "grommet";
import { Close } from "grommet-icons";
import styled from "styled-components";
import { BigNumber, ethers } from "ethers";
import ModalNFT from "./ModalNFT";

import { MINTER, ROYALTY, NFT721, IWETH } from "../constant/abi";

import NoBalance from "./NoBalance";
import Modals from "./Modals";

const formatNumb = (numb) => ethers.utils.formatEther(numb);

const MinterAddress = process.env.REACT_APP_MINTER;
const NFTAddress = process.env.REACT_APP_NFT;
const RoyaltyAddress = process.env.REACT_APP_ROYALTY;
const WETHAddress = process.env.REACT_APP_WETH;

const gasUrl = process.env.REACT_APP_GAS;

const blockUrl = process.env.REACT_APP_EXPLORER;

const formatGas = (numb) =>
  ethers.utils.parseUnits(Math.ceil(numb) + "", "gwei");

const fallbackGas = ethers.BigNumber.from(40000000000);

const StyledBox = styled(Box)`
  ::-webkit-scrollbar-track {
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(136, 136, 136, 0.4);
  }
`;

const MinterLayer = ({
  open,
  onClose,
  provider,
  account,
  minted,
  maxSupply,
  connect,
  minterImg,
  setTriger,
  setTransHash,
}) => {
  const [mintAmounts, setMintAmount] = useState(0);
  const [royalBalance, setRoyalBalance] = useState("0");
  const [share, setShare] = useState(0);
  const [royal, setRoyal] = useState("0");
  const [nftColl, setNft] = useState([]);
  const [loaded, setLoaded] = useState();
  const [balance, setBalance] = useState(0);
  const [claimed, setClaimed] = useState(true);
  const [trigger, setTrigger] = useState();
  const [toast, setToast] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadColl, setIsLoadColl] = useState(false);
  const [queryNetwork, setQuery] = useState(false);
  const [coinAmount, setCoin] = useState();

  const rpcProvider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_CONTRACT
  );

  const contractWETH = new ethers.Contract(WETHAddress, IWETH, rpcProvider);
  const contractMinter = new ethers.Contract(
    MinterAddress,
    MINTER,
    rpcProvider
  );

  const contractRoyalty = new ethers.Contract(
    RoyaltyAddress,
    ROYALTY,
    rpcProvider
  );

  const contractNFT = new ethers.Contract(NFTAddress, NFT721, rpcProvider);

  useEffect(() => {
    if (!open) return;
    getBalance(account, contractMinter);
    getRoyaltyBalance();
  }, [open, account]);

  useEffect(() => {
    loadState(account);
    getBalance(account, contractMinter);
    getRoyaltyBalance();
  }, [balance, account]);

  useEffect(() => {
    if (!loaded) return;
    setLoaded(undefined);
  }, [account]);

  const getBalance = async (_account, kontrak) => {
    if (!_account) return;
    const bal = await kontrak.balanceOf(_account);
    setBalance(bal.toString());
  };

  const loadState = useCallback(
    async (_account) => {
      if (!loaded) {
        await stateWithAcc(_account);
      } else {
        await stateWithAcc(_account);
        await loadCollection();
      }
    },
    [balance, minted]
  );

  const stateWithAcc = async (accounts) => {
    if (!accounts) return;
    try {
      setQuery(true);
      let maxShares = BigNumber.from("0");
      let totalPending = BigNumber.from("0");

      const mintOwn = await contractNFT.tryMintAmount(accounts);
      setMintAmount(mintOwn[1].toNumber());

      const _claimed = await contractMinter.minterClaimed(accounts);
      setClaimed(_claimed);

      const getId = await contractMinter.tokensOfOwner(accounts);
      await Promise.all(
        getId.map(async (i) => {
          const _share = await contractMinter.shares(i);
          maxShares = maxShares.add(_share);
          const _pending = await contractMinter.IERC20pendingPaymentPerToken(
            WETHAddress,
            i
          );
          totalPending = totalPending.add(_pending);
        })
      );
      setShare(maxShares.toNumber());
      setRoyal(formatNumb(totalPending));
    } catch (err) {
      console.error(err);
    } finally {
      setQuery(false);
    }
  };

  const getRoyaltyBalance = async () => {
    try {
      const _distri = await contractRoyalty["releasable(address,address)"](
        WETHAddress,
        MinterAddress
      );
      setRoyalBalance(formatNumb(_distri[1]));
      const _coinbalance = await contractWETH.balanceOf(MinterAddress);
      setCoin(formatNumb(_coinbalance));
    } catch (err) {
      console.error(err);
    }
  };

  const loadCollection = async () => {
    try {
      setIsLoadColl(true);
      const getId = await contractMinter.tokensOfOwner(account);
      const loads = await Promise.all(
        getId.map(async (i) => {
          const _pending = await contractMinter.IERC20pendingPaymentPerToken(
            WETHAddress,
            i
          );
          const tokenUri = await contractMinter.tokenURI(i);
          const meta = await ethers.utils.fetchJson(tokenUri);

          let item = {
            id: i.toString(),
            name: meta.name,
            image: meta.image,
            payment: formatNumb(_pending),
          };
          return item;
        })
      );
      setNft(loads);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadColl(false);
      if (nftColl.length < 2) return;
    }
  };

  async function getGas(url) {
    let data = {
      maxPriorityFeePerGas: fallbackGas,
      maxFeePerGas: fallbackGas,
    };
    try {
      const gas = await (await fetch(url)).json();

      data = {
        maxPriorityFeePerGas: formatGas(gas.fast.maxPriorityFee),
        maxFeePerGas: formatGas(gas.fast.maxFee),
      };
      return data;
    } catch {}
  }

  const distribute = async () => {
    setLoading(true);
    const gas = await getGas(gasUrl);
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const contract = new ethers.Contract(RoyaltyAddress, ROYALTY, signer);
    try {
      const tx = await contract.distributeERC20(
        WETHAddress,
        BigNumber.from(1),
        { ...gas }
      );
      setLoading(false);
      setTriger(true);
      const trans = await tx.wait();
      setTriger(true);
      setTransHash(trans.transactionHash);
    } catch (err) {
      console.error(err);
      setToast("Transaction Failed!");
      setIsSuccess(false);
    } finally {
      await getRoyaltyBalance();
      await stateWithAcc(address);
      await loadCollection();
      setQuery(false);
    }
  };

  const transferNFT = async (_receiver, _tokenId) => {
    const id = BigNumber.from(_tokenId);

    setLoading(true);
    setQuery(true);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(MinterAddress, MINTER, signer);
    try {
      const gas = await getGas(gasUrl);
      const transfer = await contract[
        "safeTransferFrom(address,address,uint256)"
      ](account, _receiver, id, { ...gas });
      setLoading(false);

      setTriger(true);
      const tx = await transfer.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setToast("Transfer Failed");
      setIsSuccess(false);
    } finally {
      setQuery(true);
      await getBalance(account, contract);
    }
  };

  const claimNFT = async () => {
    setLoading(true);
    setQuery(true);
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const contract = new ethers.Contract(MinterAddress, MINTER, signer);
    try {
      const gas = await getGas(gasUrl);
      const tx = await contract.claimMint({ ...gas });
      setLoading(false);

      setTriger(true);
      const trans = await tx.wait();
      setTriger(true);
      setTransHash(trans.transactionHash);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setToast("Mint Failed!");
      setIsSuccess(false);
    } finally {
      setQuery(false);
      await getBalance(address, contract);
    }
  };

  const claimRoyalty = async (_id) => {
    const id = BigNumber.from(_id);
    setLoading(true);
    setQuery(true);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const contract = new ethers.Contract(MinterAddress, MINTER, signer);
    try {
      const gas = await getGas(gasUrl);
      const tx = await contract.erc20Release(WETHAddress, id, {
        ...gas,
      });
      setLoading(false);

      setTriger(true);
      const trans = await tx.wait();
      setTriger(true);
      setTransHash(trans.transactionHash);
      setIsSuccess(true);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setToast("Transaction Failed!");
      setIsSuccess(false);
    } finally {
      setQuery(false);
      await loadCollection();
      await stateWithAcc(address);
    }
  };

  const totalCoin = claimed ? royal : (coinAmount * mintAmounts) / maxSupply;

  const ResponsiveGrid = ({ children, columns, ...props }) => {
    const size = useContext(ResponsiveContext);
    return (
      <Grid columns={columns[size]} {...props}>
        {children}
      </Grid>
    );
  };

  const handleLoad = async () => {
    if (balance < 1) {
      setTrigger(true);
    } else {
      await loadCollection();
      setLoaded(true);
    }
  };

  const handleDistribute = async () => {
    if (balance < 1) {
      setTriger(true);
    } else {
      distribute();
    }
  };

  const shareCheck = share === 0 ? mintAmounts : share;

  return (
    <>
      {open && (
        <Layer animate modal position="right">
          <StyledBox
            align="center"
            direction="column"
            justify={isLoading ? "center" : "start"}
            elevation="xlarge"
            overflow="auto"
          >
            <Grid
              columns={["0.4fr", "0.6fr"]}
              rows={["0.3fr", "1fr", "1fr", "1fr"]}
              areas={[
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "mint", start: [0, 1], end: [0, 2] },
                { name: "total", start: [0, 3], end: [0, 3] },
                { name: "main", start: [1, 1], end: [1, 3] },
              ]}
            >
              <StyledBox
                direction="row-responsive"
                justify="center"
                align="center"
                fill="horizontal"
                flex={false}
                border={{ color: "light-1", size: "small", side: "bottom" }}
                gridArea="header"
                pad="small"
              >
                <Button
                  primary={true}
                  icon={<Close />}
                  type="button"
                  color="active-text"
                  alignSelf="start"
                  onClick={() => onClose(undefined)}
                />
                <StyledBox
                  align="end"
                  justify="end"
                  direction="column"
                  width="full"
                >
                  <Heading
                    textAlign="start"
                    size="medium"
                    level="2"
                    margin={{ vertical: "none" }}
                  >
                    {/* <Anchor href={process.env.REACT_APP_OPENSEA_MINTER}> */}
                    SPECIAL NFT MINTER SHARE
                    {/* </Anchor> */}
                  </Heading>
                  <Paragraph size="medium" textAlign="center" fill>
                    Smart Contract Address :{" "}
                    <Anchor
                      href={`${blockUrl}/${MinterAddress}`}
                      target="_blank"
                    >
                      {MinterAddress}
                    </Anchor>
                  </Paragraph>
                </StyledBox>
              </StyledBox>

              <StyledBox
                gridArea="mint"
                align="center"
                justify="start"
                pad="small"
                gap="medium"
                margin={{ top: "large" }}
              >
                <Heading level="2" size="medium" textAlign="center">
                  WHITELIST SPECIAL NFT
                </Heading>
                <Card
                  direction="column"
                  justify="center"
                  align="center"
                  gap="none"
                >
                  <Image src={minterImg} fit="contain" fill="horizontal" />
                  <StyledBox align="center" justify="center" pad="small">
                    <Button
                      label="Claim"
                      primary={false}
                      type="button"
                      color="active-text"
                      disabled={
                        !connect ||
                        minted < maxSupply ||
                        mintAmounts === 0 ||
                        claimed ||
                        queryNetwork
                      }
                      onClick={() => claimNFT()}
                    />
                  </StyledBox>
                </Card>
                <StyledBox
                  align="start"
                  justify="start"
                  gridArea="total"
                  elevation="xlarge"
                  round="xsmall"
                  pad={{ horizontal: "xsmall" }}
                  gap="small"
                >
                  {minted >= maxSupply ? (
                    <StyledBox fill="horizontal" justify="start" gap="small">
                      <Heading level="3" textAlign="center" fill>
                        Total Special NFT Owned
                      </Heading>
                      <Paragraph
                        fill
                        size="medium"
                        textAlign="center"
                        margin={{ top: "small" }}
                      >
                        {balance} NFT
                      </Paragraph>
                    </StyledBox>
                  ) : (
                    <>
                      <Paragraph textAlign="center">
                        Total supply Base NFT must reach max supply to claim
                        special NFT
                      </Paragraph>
                      <br />
                      <Heading level="2" textAlign="center">
                        Total Base NFT Minted
                      </Heading>
                      <Stack anchor="center" fill={false}>
                        <Meter
                          max={maxSupply}
                          round
                          size="small"
                          values={[
                            {
                              color: "graph-1",
                              value: minted,
                              label: "NFT Minted",
                            },
                          ]}
                          type="circle"
                        />
                        <Paragraph fill size="small">
                          {minted}/{maxSupply}
                        </Paragraph>
                      </Stack>
                    </>
                  )}
                </StyledBox>
              </StyledBox>
              <StyledBox
                align="center"
                justify="between"
                gridArea="main"
                elevation="large"
                gap="small"
              >
                <Grid
                  fill="vertical"
                  width="full"
                  columns={["1/2", "1/2"]}
                  rows={["1fr", "0.5fr", "2fr"]}
                  pad="xsmall"
                  areas={[
                    { name: "Royalty", start: [0, 0], end: [1, 0] },
                    { name: "minted", start: [0, 1], end: [0, 1] },
                    { name: "pending", start: [1, 1], end: [1, 1] },
                    { name: "coll", start: [0, 2], end: [1, 2] },
                  ]}
                >
                  <StyledBox
                    align="center"
                    justify="between"
                    gridArea="Royalty"
                    border={{
                      color: "light-1",
                      side: "bottom",
                      style: "ridge",
                    }}
                    direction="row"
                  >
                    <StyledBox
                      align="center"
                      justify="center"
                      border={{ side: "right" }}
                      pad={{ horizontal: "xsmall" }}
                      flex={false}
                      width="50%"
                    >
                      <Heading
                        level="4"
                        margin={{ bottom: "xsmall" }}
                        textAlign="center"
                        pad="small"
                      >
                        Total Royalty in Vault
                      </Heading>
                      <Paragraph
                        size="medium"
                        textAlign="start"
                        margin={{ bottom: "xsmall", vertical: "xsmall" }}
                      >
                        {royalBalance} WETH
                      </Paragraph>
                      <Button
                        label="Distribute"
                        margin="xsmall"
                        reverse={false}
                        secondary={false}
                        disabled={!connect || royalBalance == 0 || queryNetwork}
                        type="button"
                        color="active-text"
                        size="small"
                        onClick={() => handleDistribute()}
                      />
                    </StyledBox>
                    <Stack anchor="center">
                      <Meter
                        max={maxSupply}
                        size="small"
                        values={[
                          {
                            color: "graph-1",
                            value: shareCheck,
                            label: "NFT Minted",
                          },
                        ]}
                        type="circle"
                      />
                      <StyledBox direction="column">
                        <Paragraph size="3vw" textAlign="center">
                          Share
                        </Paragraph>
                        <Paragraph fill size="3vw">
                          {queryNetwork ? "...fetching data" : shareCheck}/
                          {maxSupply}
                        </Paragraph>
                      </StyledBox>
                    </Stack>
                  </StyledBox>
                  <StyledBox
                    align="center"
                    justify="center"
                    gridArea="minted"
                    border={{
                      color: "light-1",
                      side: "right",
                      style: "outset",
                    }}
                    pad="small"
                  >
                    <Heading level="4" size="medium" textAlign="center">
                      You minted :
                    </Heading>
                    <Paragraph
                      fill
                      size="large"
                      textAlign="center"
                      color="active-text"
                      margin="none"
                    >
                      {queryNetwork ? "...fetching data" : mintAmounts} NFT
                    </Paragraph>
                  </StyledBox>
                  <StyledBox
                    align="center"
                    justify="center"
                    gridArea="pending"
                    pad="xsmall"
                  >
                    <Heading level="4" size="medium" textAlign="center">
                      Pending royalty :
                    </Heading>
                    <Paragraph
                      fill
                      size="large"
                      color="active-text"
                      margin="none"
                    >
                      {queryNetwork ? "...fetching data" : totalCoin} WETH
                    </Paragraph>
                  </StyledBox>
                  <StyledBox
                    align={loaded ? "start" : "center"}
                    justify={loaded ? "start" : "center"}
                    gridArea="coll"
                    direction="column"
                    gap="medium"
                    pad="xsmall"
                    wrap={false}
                    border={{
                      color: "light-1",
                      size: "small",
                      side: "top",
                      style: "ridge",
                    }}
                    overflow="auto"
                  >
                    {!loaded ? (
                      <Button
                        label="Load NFT"
                        reverse={false}
                        secondary={false}
                        color="active-text"
                        size="small"
                        primary={false}
                        disabled={false}
                        onClick={() => handleLoad()}
                      />
                    ) : (
                      <ResponsiveGrid
                        columns={{
                          xsmall: { size: "1/2", count: "fill" },
                          small: { size: "1/2", count: "fill" },
                          medium: { size: "1/4", count: "fill" },
                          middle: { size: "1/4", count: "fill" },
                        }}
                        width="100%"
                        gap="xsmall"
                        fill="horizontal"
                      >
                        {nftColl
                          .sort(
                            (a, b) =>
                              b.payment.split(".").shift() -
                              a.payment.split(".").shift()
                          )
                          .map((nft, i) => (
                            <ModalNFT
                              key={i}
                              id={nft.id}
                              name={nft.name}
                              image={nft.image}
                              pay={nft.payment}
                              claimFunc={claimRoyalty}
                              pending={true}
                              submit={transferNFT}
                              connect={connect}
                              contractAddress={MinterAddress}
                            />
                          ))}
                      </ResponsiveGrid>
                    )}
                    {isLoadColl && (
                      <Layer full modal plain position="center" animate={false}>
                        <StyledBox fill align="center" justify="center">
                          <Spinner size="xlarge" color="active-text" />
                        </StyledBox>
                      </Layer>
                    )}
                  </StyledBox>
                </Grid>
              </StyledBox>
            </Grid>
          </StyledBox>
          {isLoading && (
            <Layer full modal plain position="center" animate>
              <StyledBox
                fill
                align="center"
                justify="center"
                background={{ color: "light-3", opacity: "medium" }}
              >
                <Spinner size="xlarge" color="active-text" />
              </StyledBox>
            </Layer>
          )}
        </Layer>
      )}
      <NoBalance trigger={trigger} setTrigger={setTrigger} />
      <Modals toast={toast} setToast={setToast} success={isSuccess} />;
    </>
  );
};

export default MinterLayer;
