import React from "react";
import styled from "styled-components";

import Logo from "../assets/Images/uaflagsfooter.png";
import { motion } from "framer-motion";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { ReactComponent as Twitter } from "../assets/Svgs/twitter.svg";
import { ReactComponent as Facebook } from "../assets/Svgs/facebook.svg";
import { ReactComponent as Instagram } from "../assets/Svgs/instagram.svg";
import { ReactComponent as Discord } from "../assets/Svgs/discord.svg";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  text-align: center;

  img {
    background: transparent;
    width: 20vmax;
    height: auto;
  }

  h3 {
    font-size: ${(props) => props.theme.fontxxl};
    font-family: "Kaushan Script";

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontxl};
    }
  }
`;

const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 2rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }
`;

const SocialBox = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: row;

  svg {
    margin: auto;
  }
`;

const Bottom = styled.div`
  padding: auto;
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;

  justify-content: center;
  align-items: center;
  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontsm};
    span {
      transform: none !important;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);

    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };

  return (
    <Section>
      <FooterComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: 0 }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        <SocialBox>
          <Discord />
          <a href="#" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
          <Instagram />
          <Facebook />
        </SocialBox>
        <ul>
          <li onClick={() => handleScroll("#home")}>home</li>
          <li onClick={() => handleScroll(".about")}>about</li>
          <li onClick={() => handleScroll("#mintnft")}>mint</li>
          <li onClick={() => handleScroll("#dapp")}>the dapp</li>
        </ul>

        <Bottom>
          <span
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
          >
            <a target="_blank" href="https://t.me/Web6Dev" rel="noreferrer">
              <p style={{ fontSize: "30px" }}>My Telegram</p>
            </a>
            <a
              target="_blank"
              href="https://wa.me/message/DYLFL4TYTRWTA1"
              rel="noreferrer"
            >
              <p style={{ fontSize: "30px" }}>My Whatsapp</p>
            </a>
          </span>
        </Bottom>
      </FooterComponent>
    </Section>
  );
};

export default Footer;
