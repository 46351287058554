import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  touch-action: none;
  overflow: hidden;
  padding-top: 50vh;

  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  flex-direction: row;
  justify-content: center;

  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  svg {
    width: 10vw;
    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;

    g {
      path {
        stroke: ${(props) => props.theme.text};
      }
    }
  }

  @media (max-width: 48em) {
    svg {
      width: 20vw;
    }
  }
`;

const Text = styled(motion.span)`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.text};

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      yoyo: Infinity, // repeats infinite times
      ease: "easeInOut",
    },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,

    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const Loader = () => {
  return (
    <Container
      initial={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: "100%",
        opacity: 0,
      }}
      transition={{
        duration: 2,
      }}
    >
      {/* <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg">
        <g>
          <motion.path
            variants={pathVariants}
            fill="#284bb5"
            initial="hidden"
            animate="visible"
            d="M3.03,0h49.13c1.67,0,3.03,1.36,3.03,3.03v32.33c0,1.67-1.37,3.03-3.03,3.03H3.03C1.37,38.4,0,37.04,0,35.37 V3.03C0,1.36,1.37,0,3.03,0L3.03,0z"
          />
        </g>
        <g>
          <motion.path
            variants={pathVariants}
            fill="#e3cd24"
            initial="hidden"
            animate="visible"
            d="M0,19.2h55.2v16.17c0,1.67-1.37,3.03-3.03,3.03H3.03C1.37,38.4,0,37.04,0,35.37V19.2L0,19.2z"
          />
        </g>
      </svg> */}
      <Text variants={textVariants} initial="hidden" animate="visible">
        Loading...
      </Text>
    </Container>
  );
};

export default Loader;
