import { useState, useContext, useEffect, useCallback } from "react";
import {
  Layer,
  Box,
  Grid,
  Button,
  Heading,
  Paragraph,
  Stack,
  Meter,
  ResponsiveContext,
  Text,
  Spinner,
  Anchor,
} from "grommet";

import styled from "styled-components";

import { Close } from "grommet-icons";
import { ethers, BigNumber } from "ethers";

import { ROYALTY, NFT721, RELAYER } from "../constant/abi";

import ModalNFT from "./ModalNFT";
import NoBalance from "./NoBalance";
import Modals from "./Modals";

const NFTAddress = process.env.REACT_APP_NFT;
const RoyaltyAddress = process.env.REACT_APP_ROYALTY;
const HolderAddress = process.env.REACT_APP_RELAYER;
const WETHAddress = process.env.REACT_APP_WETH;

const rpcProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_RPC_CONTRACT
);

const blockUrl = process.env.REACT_APP_EXPLORER;

const contractNFT = new ethers.Contract(NFTAddress, NFT721, rpcProvider);

const contractRoyalty = new ethers.Contract(
  RoyaltyAddress,
  ROYALTY,
  rpcProvider
);

const contractHolder = new ethers.Contract(HolderAddress, RELAYER, rpcProvider);

const STORAGE = process.env.REACT_APP_STORAGE;

const formatGas = (numb) =>
  ethers.utils.parseUnits(Math.ceil(numb) + "", "gwei");

const fallbackGas = ethers.BigNumber.from(40000000000);

const StyledBox = styled(Box)`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #1a1a1a;
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) {
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #1a1a1a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(136, 136, 136, 0.4);
    ::-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) {
    }
  }
`;

const resolveIPFS = (url, root) => {
  if (!url) {
    return url;
  }

  if (!url.includes("ipfs://") || !url) {
    return url;
  }

  return url.replace("ipfs://", root);
};

const Collection = ({
  coll,
  setColl,
  account,
  provider,
  minted,
  maxSupply,
  balance,
  connect,
  setTriger,
  setTransHash,
}) => {
  const [isPostpone, setPospone] = useState(undefined);
  const [pendingRoyal, setPendingRoyal] = useState("0");
  const [collection, setCollection] = useState([]);
  const [tokenId, setTokenId] = useState([]);
  const [loaded, setLoaded] = useState();
  const [pool, setPool] = useState("0");
  const [royalty, setRoyalty] = useState("0");
  const [trigger, setTrigger] = useState();
  const [toast, setToast] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadColl, setIsLoadColl] = useState(false);
  const [queryNetwork, setQuery] = useState(false);

  useEffect(() => {
    if (!coll) return;
    setQuery(true);
    stateWithoutAcc();
    loadStat(account);
    setQuery(false);
    if (!account) {
      setPendingRoyal("0");
    }
  }, [coll, account]);

  useEffect(() => {
    fetchId(account);
  }, [balance]);

  useEffect(() => {
    if (!loaded) return;
    setLoaded(undefined);
    setCollection([]);
  }, [account]);

  const fetchId = useCallback(
    async (_account) => {
      if (!_account) {
        setTokenId([]);
        return;
      }
      const getId = await contractNFT.tokensOfOwner(_account);
      setTokenId(getId);
    },
    [tokenId]
  );

  const loadStat = async (_account) => {
    try {
      setQuery(true);
      if (!_account) {
        await getPending(contractHolder, undefined);
      } else {
        await getPending(contractHolder, _account);
      }
      setQuery(false);
    } catch (err) {
      setQuery(false);
    }
  };

  const stateWithoutAcc = useCallback(async () => {
    const postponed = await contractHolder.projectKilled();
    setPospone(postponed);
    if (!postponed) return;
    const _royalty = await contractRoyalty["releasable(address,address)"](
      WETHAddress,
      HolderAddress
    );
    setRoyalty(ethers.utils.formatEther(_royalty[1]));
  }, [royalty]);

  const getPending = useCallback(
    async (kontrak, _account) => {
      try {
        if (_account) {
          const royal = await kontrak.pendingRoyalty(WETHAddress, _account);
          setPendingRoyal(ethers.utils.formatEther(royal));
        }
        const blocklength = await kontrak.blockLengthERC20(WETHAddress);
        const totalBlock = blocklength.toNumber();
        if (totalBlock === 0) {
          setPool(0);
        } else {
          const blocks = [...Array(totalBlock).keys()];
          let _pool = BigNumber.from(0);
          await Promise.all(
            blocks.map(async (i) => {
              const blockmined = await kontrak.querryBlockERC20(
                WETHAddress,
                BigNumber.from(i)
              );
              const value = blockmined[1];
              _pool = _pool.add(value);
            })
          );
          setPool(ethers.utils.formatEther(_pool));
        }
      } catch (err) {
        console.error(err);
      }
    },
    [pendingRoyal]
  );

  async function getGas() {
    let data = {
      maxPriorityFeePerGas: fallbackGas,
      maxFeePerGas: fallbackGas,
    };
    try {
      const gas = await (await fetch(process.env.REACT_APP_GAS)).json();
      data = {
        maxPriorityFeePerGas: formatGas(gas.fast.maxPriorityFee),
        maxFeePerGas: formatGas(gas.fast.maxFee),
      };
      return data;
    } catch {}
  }

  const transferNFT = async (_receiver, _tokenId) => {
    const id = BigNumber.from(_tokenId);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NFTAddress, NFT721, signer);
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas();
      const transfer = await contract[
        "safeTransferFrom(address,address,uint256)"
      ](account, _receiver, id, { ...gas });
      setLoading(false);
      setTriger(true);
      const tx = await transfer.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      setLoading(false);
      setToast("Transfer Failed");
      setIsSuccess(false);
    } finally {
      loadCollection(undefined, false, _tokenId);
      setQuery(false);
    }
  };

  const handleLoad = async () => {
    if (balance < 1) {
      setTrigger(true);
    } else {
      try {
        setIsLoadColl(true);
        setLoaded(true);
        if (balance > 20) {
          const arr = [];
          for (let i = 0; i < 20; i++) {
            arr.push(tokenId[i]);
          }
          loadCollection(arr, true, undefined);
        } else {
          await loadCollection(tokenId, true, undefined);
        }
        setIsLoadColl(false);
      } catch (err) {
        console.error(err);
        setIsLoadColl(false);
      }
    }
  };

  const onMore = async () => {
    try {
      setIsLoadColl(true);
      const arr = [];
      for (let i = collection.length; i < collection.length + 20; i++) {
        arr.push(tokenId[i]);
        if (arr[arr.length - 1] === tokenId[tokenId.length - 1]) {
          break;
        }
      }
      await loadCollection(arr, true, undefined);
    } catch (err) {
      console.error(err);
      setIsLoadColl(false);
    } finally {
      setIsLoadColl(false);
    }
  };

  const loadCollection = async (_id, more, _tokenId) => {
    try {
      if (more) {
        if (!_id) return;
        const loads = await Promise.all(
          _id.map(async (i) => {
            const tokenUri = await contractNFT.tokenURI(i);
            const metadata = tokenUri.split("/").pop();
            const imageURI = metadata.split(".").shift();
            const thumb = `${process.env.REACT_APP_THUMB}${imageURI}.png`;
            const metaImage = `${process.env.REACT_APP_IPFS_BASE}${imageURI}.png`;
            const resolvedImage = resolveIPFS(metaImage, STORAGE);
            let item = {
              id: i.toNumber(),
              name: `#${i.toString()} NeverForget.24.Feb.2022`,
              thumbImage: thumb,
              image: resolvedImage,
            };

            return item;
          })
        );
        loads.forEach((i) => setCollection((prev) => [...prev, i]));
      } else {
        setCollection(collection.filter((item) => item.id !== _tokenId));
      }
    } catch (err) {
      setLoaded(false);
      setIsLoadColl(false);
      console.error(err);
    }
  };

  const distributePool = async () => {
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const contract = new ethers.Contract(HolderAddress, RELAYER, signer);
    setLoading(true);
    setQuery(true);
    try {
      const gas = await getGas();
      const tx = await contract.distributeRoyaltyERC20(WETHAddress, { ...gas });
      setLoading(false);
      setTriger(true);
      await tx.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
    } catch (err) {
      setToast("Transaction Failed!");
      setIsSuccess(false);
      setLoading(false);
    } finally {
      await stateWithoutAcc();
      await getPending(contract, address);
      setQuery(false);
    }
  };

  const royalClaim = async () => {
    setLoading(true);
    setQuery(true);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const contract = new ethers.Contract(HolderAddress, RELAYER, signer);
    try {
      const gas = await getGas();
      const tx = await contract.claimRoyaltyERC20(WETHAddress, address, {
        ...gas,
      });
      setLoading(false);
      setTriger(true);
      const trans = await tx.wait();
      setTriger(true);
      setTransHash(trans.transactionHash);
    } catch (err) {
      setLoading(false);
      setToast("Transaction Failed!");
      setIsSuccess(false);
    } finally {
      await getPending(contract, address);
      setQuery(false);
    }
  };

  const ResponsiveGrid = ({ children, columns, ...props }) => {
    const size = useContext(ResponsiveContext);
    return (
      <Grid columns={columns[size]} {...props}>
        {children}
      </Grid>
    );
  };

  return (
    <>
      {coll && (
        <Layer animate modal position="right" full="vertical">
          <StyledBox
            direction="column"
            width="full"
            height="100%"
            fill="vertical"
            elevation="xlarge"
            justify={isLoading ? "center" : undefined}
            align={isLoading ? "center" : undefined}
          >
            <Grid
              columns={["1/3", "2/3"]}
              rows={["0.6fr", "1fr", "1fr", "1fr"]}
              areas={[
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "mint", start: [0, 1], end: [0, 2] },
                { name: "total", start: [0, 3], end: [0, 3] },
                { name: "main", start: [1, 1], end: [1, 3] },
              ]}
              gap="xxsmall"
              fill="vertical"
              flex={false}
            >
              <StyledBox
                direction="column"
                justify="start"
                align="center"
                border={{ color: "light-1", size: "small", side: "bottom" }}
                gridArea="header"
                pad={{ bottom: "medium" }}
                flex
              >
                <StyledBox
                  align="center"
                  justify="center"
                  basis="xxsmall"
                  alignSelf="start"
                  margin={{ horizontal: "small", top: "large" }}
                >
                  <Button
                    primary={true}
                    icon={<Close />}
                    secondary={false}
                    type="button"
                    hoverIndicator
                    size="small"
                    color="active-text"
                    reverse={false}
                    onClick={() => setColl(undefined)}
                  />
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="end"
                  direction="column"
                  fill="vertical"
                >
                  <Heading
                    textAlign="center"
                    size="medium"
                    level="2"
                    margin={{ bottom: "none", vertical: "none" }}
                  >
                    <Anchor
                      href={process.env.REACT_APP_OPENSEA_NFT}
                      target="_blank"
                    >
                      NFT Collection
                    </Anchor>
                  </Heading>
                  <Paragraph
                    margin={{ vertical: "none" }}
                    size="medium"
                    textAlign="center"
                    fill
                  >
                    NFT Contract Address :{" "}
                    <Anchor href={`${blockUrl}/${NFTAddress}`} target="_blank">
                      {NFTAddress}
                    </Anchor>
                  </Paragraph>
                </StyledBox>
              </StyledBox>
              <StyledBox
                gridArea="mint"
                fill="vertical"
                elevation="medium"
                round="small"
                flex={false}
                responsive
                overflow="auto"
              >
                <StyledBox
                  align="center"
                  justify="end"
                  border={{ side: "bottom", size: "small" }}
                  direction="column"
                  responsive
                  gap="small"
                  pad={{ bottom: "small" }}
                  flex={false}
                >
                  <Heading level="4" size="small" textAlign="center">
                    Total Royalty Holder in Vault
                  </Heading>
                  <Paragraph size="medium" textAlign="center">
                    {royalty} WETH
                  </Paragraph>
                  <StyledBox align="center" justify="center" responsive>
                    <Button
                      label="Distribute"
                      reverse={false}
                      secondary={false}
                      color="active-text"
                      size="small"
                      primary={false}
                      disabled={connect || royalty == 0 || queryNetwork}
                      onClick={() => distributePool()}
                    />
                  </StyledBox>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="end"
                  gridArea="minted"
                  border={{
                    color: "light-1",
                    side: "bottom",
                    style: "outset",
                  }}
                  pad={{ vertical: "small", horizontal: "small" }}
                  responsive
                  wrap={false}
                  flex={false}
                >
                  <Heading
                    level="4"
                    size="medium"
                    textAlign="center"
                    margin={{
                      bottom: "xsmall",
                      top: "none",
                      horizontal: "none",
                    }}
                  >
                    Total NFT in Wallet
                  </Heading>
                  <Paragraph
                    fill
                    size="large"
                    color="active-text"
                    margin={{
                      bottom: "none",
                      top: "none",
                      horizontal: "none",
                    }}
                    textAlign="center"
                  >
                    {balance} NFT
                  </Paragraph>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="end"
                  pad={{ vertical: "small" }}
                  border={{ color: "active-text", side: "bottom" }}
                  responsive
                  flex={false}
                >
                  <Heading
                    level="4"
                    textAlign="center"
                    margin={{ top: "none", bottom: "xsmall" }}
                  >
                    Project Status :
                  </Heading>
                  <Paragraph
                    fill
                    size="large"
                    color="active-text"
                    margin={{
                      horizontal: "none",
                      top: "none",
                      bottom: "xsmall",
                    }}
                    textAlign="center"
                  >
                    {isPostpone ? `Postponed` : `Running`}
                  </Paragraph>
                </StyledBox>
                <StyledBox
                  align="center"
                  justify="center"
                  pad="small"
                  flex={false}
                  direction="column"
                  margin={{ top: "large" }}
                  gap="small"
                >
                  <Heading level="3" textAlign="center">
                    Pending Royalty
                  </Heading>
                  <Paragraph size="xlarge" textAlign="center">
                    {pendingRoyal} WETH
                  </Paragraph>
                  <Button
                    label={<Paragraph size="0.8rem">Claim Royalty</Paragraph>}
                    type="button"
                    color="active-text"
                    size="small"
                    onClick={() => {
                      royalClaim();
                    }}
                    disabled={connect || pendingRoyal == 0 || queryNetwork}
                  />
                </StyledBox>
              </StyledBox>
              <StyledBox
                align="center"
                justify="start"
                direction="row-responsive"
                gridArea="total"
                elevation="xlarge"
                round="xsmall"
                margin="small"
                responsive
                pad="small"
                overflow="auto"
              >
                {minted >= maxSupply ? (
                  <StyledBox
                    direction="column"
                    width="40vmax"
                    gap="medium"
                    height="full"
                    justify="center"
                    align="center"
                    round="medium"
                    pad="small"
                  >
                    <Heading level="4" textAlign="center">
                      Total Pool
                    </Heading>
                    <Text size="xlarge" textAlign="center">
                      {pool} WETH
                    </Text>
                  </StyledBox>
                ) : (
                  <>
                    <Heading level="2" textAlign="center">
                      Total NFT Minted
                    </Heading>
                    <Stack anchor="center" fill={false}>
                      <Meter
                        max={maxSupply}
                        round
                        size="small"
                        values={[
                          {
                            color: "graph-1",
                            value: minted,
                            label: "NFT Minted",
                          },
                        ]}
                        type="circle"
                      />
                      <Paragraph fill size="small">
                        {minted}/{maxSupply}
                      </Paragraph>
                    </Stack>
                  </>
                )}
              </StyledBox>
              <StyledBox
                align={loaded ? "start" : "center"}
                justify={loaded ? "start" : "center"}
                gridArea="main"
                direction="column"
                gap="medium"
                pad="small"
                flex="grow"
                wrap={false}
                overflow="auto"
                responsive
              >
                {!loaded ? (
                  <Button
                    label="Load NFT"
                    color="active-text"
                    size="small"
                    onClick={() => handleLoad()}
                  />
                ) : (
                  <>
                    <ResponsiveGrid
                      columns={{
                        xsmall: { size: "1/2", count: "fill" },
                        small: { size: "1/2", count: "fill" },
                        medium: { size: "1/4", count: "fill" },
                        middle: { size: "1/4", count: "fill" },
                      }}
                      width="100%"
                      gap="xsmall"
                      fill="horizontal"
                    >
                      {/* <InfiniteScroll
                        items={collection}
                        onMore={() => {
                          for (
                            let i = idRendered.length;
                            i < idRendered.length + 20;
                            i++
                          ) {
                            idRendered.push(tokenId[i + 1]);
                          }
                          setCollection(idRendered);
                        }}
                      > */}
                      {collection.map((nft, i) => (
                        <ModalNFT
                          key={i.toString()}
                          name={nft.name}
                          id={nft.id}
                          image={nft.image}
                          thumbImage={nft.thumbImage}
                          provider={provider}
                          account={account}
                          submit={transferNFT}
                          connect={connect}
                          pending={false}
                          contractAddress={NFTAddress}
                        />
                      ))}
                      {/* </InfiniteScroll> */}
                    </ResponsiveGrid>
                    {collection.length === tokenId.length ? undefined : (
                      <Button
                        label="Load More"
                        size="small"
                        fill="horizontal"
                        margin={{ top: "8px" }}
                        disabled={isLoadColl}
                        onClick={() => onMore()}
                      />
                    )}
                    {isLoadColl && (
                      <Layer full modal plain position="center" animate={false}>
                        <StyledBox
                          fill
                          align="center"
                          justify="center"
                          alignSelf="center"
                        >
                          <Spinner size="xlarge" color="active-text" />
                        </StyledBox>
                      </Layer>
                    )}
                  </>
                )}
              </StyledBox>
            </Grid>
          </StyledBox>
          {isLoading && (
            <Layer
              full
              modal
              background={{ color: "light-3", opacity: "strong" }}
              position="center"
              animate={false}
            >
              <StyledBox fill align="center" justify="center">
                <Spinner size="xlarge" color="active-text" />
              </StyledBox>
            </Layer>
          )}
        </Layer>
      )}
      <NoBalance trigger={trigger} setTrigger={setTrigger} />
      <Modals toast={toast} setToast={setToast} success={isSuccess} />;
    </>
  );
};

export default Collection;
