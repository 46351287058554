import { useState, useCallback } from "react";
import {
  Box,
  Button,
  Heading,
  Form,
  FormField,
  FileInput,
  Image,
  Text,
} from "grommet";
import { ethers } from "ethers";
import { NFTStorage, File } from "nft.storage";
import validUrl from "valid-url";

import { DAO } from "../constant/abi";

import Modals from "./Modals";

const defaultValue = {
  title: "",
  url: "",
  address: "",
  description: "",
};

const DAOAddress = process.env.REACT_APP_DAO;

const beProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_BE
);

const token = process.env.REACT_APP_STORAGE_TOKEN;

const gasUrl = process.env.REACT_APP_GAS;

const endPoint = process.env.REACT_APP_BACKEND_URL;

const formatGas = (numb) =>
  ethers.utils.parseUnits(Math.ceil(numb) + "", "gwei");

const fallbackGas = ethers.BigNumber.from(40000000000);

const SubmitProposal = ({
  provider,
  metReq,
  stage,
  setLoading,
  setHash,
  setSubmiting,
  fetchStage,
  setTriger,
  setTransHash,
  relayer,
}) => {
  const [valid, setValid] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [image, setImage] = useState(null);
  const [toast, setToast] = useState();
  const [isSuccess, setIsSuccess] = useState();

  const _isAddress = useCallback((_addr) => {
    try {
      const address = ethers.utils.isAddress(_addr);
      return address;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "webp"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  async function handleFileChange(e) {
    /* upload cover image to ipfs and save hash to state */
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (isValidFileUploaded(file)) {
      setImage(file);
    } else {
      setImage(image);
    }
  }

  async function getGas(url) {
    let data = {
      maxPriorityFeePerGas: fallbackGas,
      maxFeePerGas: fallbackGas,
    };
    try {
      const gas = await (await fetch(url)).json();
      data = {
        maxPriorityFeePerGas: formatGas(gas.fast.maxPriorityFee),
        maxFeePerGas: formatGas(gas.fast.maxFee),
      };
      return data;
    } catch {}
  }

  const generate = (numb) => {
    const byteslike = ethers.utils.toUtf8Bytes(
      `${process.env.REACT_APP_MIDDLE}${numb}`
    );
    const circleing = ethers.utils.keccak256(byteslike);
    const hash = ethers.utils.solidityKeccak256(
      ["string", "bytes32"],
      [process.env.REACT_APP_OPENING, circleing]
    );

    const relay = relayer.signDigest(hash);
    return relay;
  };

  const submitProposal = async () => {
    if (!provider) return;
    setLoading(true);
    setSubmiting(true);
    const signer = provider.getSigner();
    const proposer = await signer.getAddress();
    const contract = new ethers.Contract(DAOAddress, DAO, signer);
    const blockNumbers = await contract.getBlock();
    const client = new NFTStorage({ token: token });
    const beBlock = await beProvider.getBlockNumber();
    const blockBefore = await beProvider.getBlock(beBlock);
    const deadline = blockBefore.timestamp + 300;
    const relayHash = generate(deadline);
    let cid = "";
    try {
      const hash = ethers.utils.solidityKeccak256(
        ["address", "uint256"],
        [value.address, beBlock]
      );
      const metaDummy = {
        blocks: blockNumbers.toString(),
        name: `Proposal ID #${blockNumbers}`,
        title: value.title,
        url: value.url,
        donation: value.address,
        proposer: proposer,
        description: value.description,
      };
      const gas = await getGas(gasUrl);
      const _submit = await contract.proposalEntry(value.address, hash, {
        ...gas,
      });
      await fetch(`${endPoint}/store/${relayHash.compact}&${deadline}`, {
        method: "POST",
        body: JSON.stringify({
          hash: hash,
          data: JSON.stringify(metaDummy),
        }),
        headers: { "Content-type": "application/json" },
      });
      setSubmiting(false);
      setLoading(false);

      setTriger(true);
      const tx = await _submit.wait();
      setTriger(true);
      setTransHash(tx.transactionHash);
      if (!image) {
        const metadata = {
          name: `Proposal ID #${blockNumbers}`,
          title: value.title,
          url: value.url,
          image: "",
          description: value.description,
        };
        const content = new File(
          [JSON.stringify(metadata, null, 4)],
          `proposal#${blockNumbers}.json`
        );
        cid = await client.storeBlob(content);
      } else {
        const content = image;
        const cidImage = await client.storeBlob(content);
        const metadata = {
          name: `Proposal ID #${blockNumbers}`,
          title: value.title,
          url: value.url,
          image: `ipfs://${cidImage}/`,
          description: value.description,
        };
        const uploadFile = new File(
          [JSON.stringify(metadata, null, 4)],
          `proposal#${blockNumbers}.json`
        );
        cid = await client.storeBlob(uploadFile);
      }

      await fetch(`${endPoint}/govern/${relayHash.compact}&${deadline}`, {
        method: "POST",
        body: JSON.stringify(
          {
            blocks: blockNumbers,
            dna: hash,
            content: cid,
          },
          null,
          4
        ),
        headers: { "Content-type": "application/json" },
      });
    } catch (err) {
      setHash(false);
      setLoading(false);
      setSubmiting(false);
      console.error(err);
      setToast("Submiting New Proposal Failed");
      setIsSuccess(false);
    } finally {
      setLoading(false);
      await fetchStage();
    }
  };

  return (
    <>
      <Box
        align="center"
        justify="center"
        elevation="large"
        round="small"
        direction="row-responsive"
        basis="auto"
        fill="horizontal"
      >
        <Box align="center" justify="center" alignSelf="center" width="100vmax">
          <Heading level="2" textAlign="center">
            Submit New Proposal
          </Heading>
          {image && (
            <Box align="center" justify="center" basis="small" round="medium">
              <Image fit="cover" src={URL.createObjectURL(image)} />
            </Box>
          )}
        </Box>
        <Box
          align="stretch"
          overflow="auto"
          responsive
          direction="column"
          pad="xsmall"
          width="100vmax"
        >
          <Form
            value={value}
            validate="change"
            onChange={(nextValue, { touched }) => {
              setValue(nextValue);
            }}
            onValidate={(validationResults) => {
              setValid(validationResults.valid);
            }}
          >
            <FormField
              label="Title"
              name="title"
              required
              validate={[
                { regexp: /^[a-z]/i },
                (title) => {
                  if (title && title.length === 1)
                    return "must be >1 character";
                  return undefined;
                },
              ]}
            />
            <FormField
              label="URL"
              name="url"
              required
              validate={[
                { regexp: /^[a-z]/i },
                (url) => {
                  if (!validUrl.isUri(url)) return "URL not Valid";
                  return undefined;
                },
              ]}
            />
            <FormField
              label="Donation Receiver Address"
              name="address"
              required
              validate={[
                (address) => {
                  if (!_isAddress(address))
                    return "Please enter valid Wallet Address";
                  return undefined;
                },
              ]}
            />
            <FormField
              label="Short Description"
              name="description"
              required
              validate={[
                { regexp: /^[a-z]/i },
                (description) => {
                  if (
                    description &&
                    (description.length < 5 || description.length > 128)
                  )
                    return "must be 5 to 128 character";
                  return undefined;
                },
              ]}
            />
            <Box width="50vmax" gap="small">
              <Text margin="small">Upload Image</Text>
              <FileInput
                name="image"
                id="image"
                multiple={false}
                onChange={handleFileChange}
              />
            </Box>
            <Box
              align="center"
              justify="center"
              margin={{ vertical: "medium" }}
              border={{
                color: "active-text",
                side: "top",
                size: "small",
              }}
              pad="small"
              fill="horizontal"
            >
              <Button
                label="Submit"
                margin={{ top: "small" }}
                color="active-text"
                disabled={!valid || !metReq || stage !== 1}
                onClick={() => submitProposal()}
              />
            </Box>
          </Form>
        </Box>
      </Box>
      <Modals toast={toast} setToast={setToast} success={isSuccess} />
    </>
  );
};

export default SubmitProposal;
