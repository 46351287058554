import "../index.css";
import { useParams } from "react-router-dom";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { ReactComponent as Rarible } from "../assets/Svgs/rarible.svg";
import { ReactComponent as Opensea } from "../assets/Svgs/opensea-blue.svg";
import flags from "../assets/Images/ukflagsmall.png";
import { ethers } from "ethers";
import { useState } from "react";

const Minting = () => {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(null);

  const amount = useParams();
  const cost = (0.0001 * amount.amount).toString();

  const signEthereum = async () => {
    try {
      const _providers = new ethers.providers.Web3Provider(window.ethereum);

      await _providers.send("eth_requestAccounts", []);
      setProvider(_providers);
      const accounts = await _providers.listAccounts();
      setAccount(accounts[0]);
      const chainIds = await _providers.getNetwork();
      setChainId(chainIds.chainId);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <nav className="absolute flex px-2 py-3 backdrop-blur-lg backdrop-contrast-75 overflow-hidden text-white w-full">
        <img src={flags} className="w-10" />
        <span className="font-sans font-semibold">#NeverForget NFT</span>
      </nav>
      <div className="bg-gradient-to-tl from-black/95 px-4 lg:py-8 via-slate-900/95 to-slate-800/95  w-screen h-screen text-white flex">
        <div className="m-auto border-2 w-full lg:w-1/3 border-blue-300 box-border bg-gradient-to-b from-black via-slate-900 to-slate-800/40 rounded-xl flex flex-col gap-2 p-4 shadow-2xl shadow-blue-400/80">
          <h1 className="text-2xl lg:text-4xl font-mono font-bold border-b-4">
            Mint Checkout
          </h1>
          <div className="flex flex-col">
            <p className="text-center">Smart Contract Address</p>
            <a
              href="https://polygonscan.com/address/0xd800bE7fF3Dcb3e5Ab3E8336BF03c5c14bEDF31b"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-300 font-semibold truncate text-center"
            >
              0xd800bE7fF3Dcb3e5Ab3E8336BF03c5c14bEDF31b
            </a>
          </div>
          <div className="overflow-hidden px-10 py-3">
            <img
              src="https://bafybeih56k7nqplfnodfdv3kuqaob4ooy6p4kwkon5omp7mxayzmwz6spa.ipfs.nftstorage.link/openseaProfile.gif"
              className="w-full rounded-xl h-full"
            />
          </div>
          <div className="flex flex-col gap-0">
            <h3 className="font-mono font-semibold text-center text-xl">
              Total Mint Amount: {amount.amount} NFT
            </h3>
            <h3 className="font-mono font-semibold text-center text-xl">
              Total Cost: {cost} Matic
            </h3>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex justify-around gap-2">
              <button
                onClick={() => signEthereum()}
                className="px-3 active:scale-90 mx-auto py-2 bg-gradient-to-r from-blue-700 via-blue-500 to-blue-400 font-semibold rounded-xl"
              >
                Mint With Matic
              </button>
              <CrossmintPayButton
                collectionTitle="test net nft"
                collectionDescription="test mumbai nft"
                collectionPhoto="https://bafybeih56k7nqplfnodfdv3kuqaob4ooy6p4kwkon5omp7mxayzmwz6spa.ipfs.nftstorage.link/openseaProfile.gif"
                clientId="93e421d4-7e0a-4a53-b5fc-816c29ebdf9c"
                mintConfig={{
                  type: "erc-721",
                  totalPrice: cost,
                  _mintAmount: amount.amount,
                }}
                environment="staging"
                className="px-3 active:scale-90 mx-auto py-2 bg-blue-400 font-semibold rounded-xl text-xs"
              />
            </div>
            <div className="flex align-middle items-center justify-evenly w-full">
              <p className="text-xl font-bold">Market Place :</p>
              <a
                href="https://opensea.io/collection/neverforget-24-feb-2022-by-vinogradov-demidko"
                target="_blank"
                rel="noreferrer"
              >
                <Opensea className="w-10 h-10 rounded-md" />
              </a>
              <a
                href="https://rarible.com/neverforget-24-feb-2022-by-vinogradov-demidko/items"
                target="_blank"
                rel="noreferrer"
              >
                <Rarible className="w-10 h-10 rounded-md" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Minting;
