import { Layer, Box, Heading, Button } from "grommet";
import { Alert, Close } from "grommet-icons";
import { useEffect } from "react";

const NoBalance = ({ trigger, setTrigger }) => {
  useEffect(() => {
    if (!trigger) return;
    setTimeout(() => {
      setTrigger(undefined);
    }, 3000);
    return () => {};
  }, [trigger]);

  return (
    <>
      {trigger && (
        <Layer
          animate
          modal={false}
          position="bottom"
          full="horizontal"
          responsive={false}
          justify="center"
        >
          <Box
            align="center"
            justify="between"
            direction="row"
            background={{ color: "status-critical" }}
            round={{ corner: "top", size: "xsmall" }}
            pad={{ horizontal: "medium" }}
          >
            <Box align="center" direction="row" gap="small">
              <Alert size="medium" />
              <Heading
                responsive
                textAlign="center"
                size="5vmin"
                align="center"
              >
                THERE ARE NO NFT IN YOUR WALLET
              </Heading>
            </Box>
            <Box align="start" justify="start" alignSelf="end" responsive>
              <Button
                color="active-text"
                primary={false}
                icon={<Close />}
                onClick={() => setTrigger(undefined)}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default NoBalance;
