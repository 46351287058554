import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/victim.jpg";

const Section = styled.section`
  height: 100vh;
  width: 80vw;
  margin: 0 auto;
  background-image: ${(props) => `url(${props._image})`};
  background-size: cover;
  background-position: 65%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  @media (max-width: 48em) {
    width: 90vw;
  }
`;
const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(247, 247, 247, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  backdrop-filter: blur(6px);

  @media (max-width: 64em) {
    justify-content: center;
  }
`;

const BannerComponent = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  color: ${(props) => props.theme.text};
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  font-style: oblique 30deg;
  text-decoration: underline;
  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 64em) {
    margin: 1rem 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    margin: 0.5rem 0;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  span {
    display: block;
    background-color: ${(props) => props.theme.body};
    padding: 1rem 2rem;
  }
  a {
    color: white;
  }
`;

const Poem = () => {
  return (
    <Section _image="" backgroundColor={"#000000"}>
      <Container id="up">
        {/* <BannerComponent>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="8"
            data-scroll-target="#up"
          >
            <a
              href="https://www.nytimes.com/2022/04/04/world/europe/bucha-ukraine-bodies.html"
              target="_blank"
              rel="noreferrer"
            >
              "Butchers of Bucha...
            </a>
          </span>
        </BannerComponent>
        <BannerComponent>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-6"
            data-scroll-target="#up"
          >
            <a
              href="https://metro.co.uk/2022/04/10/russian-soldier-arrested-for-raping-baby-in-ukraine-16439692/"
              target="_blank"
              rel="noreferrer"
            >
              Rape and Murder of Children,
            </a>
          </span>
        </BannerComponent>
        <BannerComponent>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="6"
            data-scroll-target="#up"
          >
            <a
              href="https://metro.co.uk/2022/04/04/ukraine-war-horrifying-picture-shows-swastika-on-corpse-of-rape-victim-16402227/"
              target="_blank"
              rel="noreferrer"
            >
              Swastika Marks on Corpses.
            </a>
          </span>
        </BannerComponent>
        <BannerComponent>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-4"
            data-scroll-target="#up"
          >
            <a
              href="https://www.thetimes.co.uk/article/the-village-in-ukraine-where-russians-looted-murdered-and-raped-9jznllf02"
              target="_blank"
              rel="noreferrer"
            >
              In the name of
            </a>
          </span>
        </BannerComponent>
        <BannerComponent>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="6"
            data-scroll-target="#up"
          >
            <a
              href="https://www.smh.com.au/world/europe/russian-soldiers-kept-women-in-a-basement-for-25-days-nine-are-now-said-to-be-pregnant-20220412-p5actf.html"
              target="_blank"
              rel="noreferrer"
            >
              Putin's Hollow Victory Parade..."
            </a>
          </span>
        </BannerComponent> */}
      </Container>
    </Section>
  );
};

export default Poem;
