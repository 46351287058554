export const NFT721 = JSON.parse(process.env.REACT_APP_NFT_KONTRAK);
export const RELAYER = JSON.parse(process.env.REACT_APP_RELAYER_KONTRAK);
export const MINTER = JSON.parse(process.env.REACT_APP_MINTER_KONTRAK);
export const DAO = JSON.parse(process.env.REACT_APP_DAO_KONTRAK);
export const ROYALTY = JSON.parse(process.env.REACT_APP_ROYALTY_KONTRAK);
export const CLOUD = JSON.parse(process.env.REACT_APP_CLOUD);
export const IWETH = [
  "event Approval(address indexed owner, address indexed spender, uint256 value)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function balanceOf(address account) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address recipient, uint256 amount) returns (bool)",
  "function transferFrom(address sender, address recipient, uint256 amount) returns (bool)",
];

export const MYTOKEN = [
  "constructor()",
  "event AdminChanged(address previousAdmin, address newAdmin)",
  "event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId)",
  "event ApprovalForAll(address indexed owner, address indexed operator, bool approved)",
  "event BeaconUpgraded(address indexed beacon)",
  "event DelegateChanged(address indexed delegator, address indexed fromDelegate, address indexed toDelegate)",
  "event DelegateVotesChanged(address indexed delegate, uint256 previousBalance, uint256 newBalance)",
  "event Initialized(uint8 version)",
  "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
  "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
  "event Upgraded(address indexed implementation)",
  "function DOMAIN_SEPARATOR() view returns (bytes32)",
  "function _contractURI() view returns (string)",
  "function approve(address to, uint256 tokenId)",
  "function balanceOf(address owner) view returns (uint256)",
  "function contractURI() view returns (string)",
  "function cost() view returns (uint256)",
  "function delegate(address delegatee)",
  "function delegateBySig(address delegatee, uint256 nonce, uint256 expiry, uint8 v, bytes32 r, bytes32 s)",
  "function delegates(address account) view returns (address)",
  "function getApproved(uint256 tokenId) view returns (address)",
  "function getPastTotalSupply(uint256 blockNumber) view returns (uint256)",
  "function getPastVotes(address account, uint256 blockNumber) view returns (uint256)",
  "function getVotes(address account) view returns (uint256)",
  "function initialize(string _name, string _symb, string _contractsurl, string _cid, uint16 _num, address _ownerContract)",
  "function isApprovedForAll(address owner, address operator) view returns (bool)",
  "function mint(uint256 _amount) payable",
  "function name() view returns (string)",
  "function nonces(address owner) view returns (uint256)",
  "function owner() view returns (address)",
  "function ownerOf(uint256 tokenId) view returns (address)",
  "function proxiableUUID() view returns (bytes32)",
  "function renounceOwnership()",
  "function safeTransferFrom(address from, address to, uint256 tokenId)",
  "function safeTransferFrom(address from, address to, uint256 tokenId, bytes _data)",
  "function setApprovalForAll(address operator, bool approved)",
  "function supportsInterface(bytes4 interfaceId) view returns (bool)",
  "function symbol() view returns (string)",
  "function tokenByIndex(uint256 index) view returns (uint256)",
  "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)",
  "function tokenURI(uint256 _tokenId) view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function transferFrom(address from, address to, uint256 tokenId)",
  "function transferOwnership(address newOwner)",
  "function upgradeTo(address newImplementation)",
  "function upgradeToAndCall(address newImplementation, bytes data) payable",
  "function uriPrefix() view returns (string)",
  "function uriSuffix() view returns (string)",
  "function withdraw()",
];
